import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material";
import EmptyState from "../../Components/EmptyState";
import api from "../../services/api";

const SentMessages = () => {
  const [sent, setSent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [messageType, setMessageType] = useState("text");

  useEffect(() => {
    setIsLoading(true);
    api.campaign
      .getSentMessages()
      .then((res) => {
        setSent(res.data?.messages);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, []);

  const handleTabChange = (event, newValue) => {
    setMessageType(newValue);
  };

  const renderTable = () => {
    if (sent.length < 1) {
      return (
        <div className="flex justify-center">
          <EmptyState
            to={"/dashboard/compose-sms"}
            stateMessage={"No message sent yet"}
            buttonMessage={"Compose message"}
          />
        </div>
      );
    }

    const filteredMessages = sent.filter(
      (message) => message.type === messageType
    );

    let columns;
    switch (messageType) {
      case "text":
      case "flash":
        columns = [
          { label: "Recipients", key: "recipients" },
          { label: "Message", key: "message" },
          { label: "Status", key: "status" },
          { label: "SMS ID", key: "sms_shoot_id" },
          { label: "Sender ID", key: "sender_id" },
          { label: "Espee Deducted", key: "espess_deducted" },
          { label: "Failed Contacts", key: "failed_contacts" },
        ];
        break;
      case "wap":
        columns = [
          { label: "Recipients", key: "recipients" },
          { label: "Message", key: "message" },
          { label: "WAP Title", key: "wapTitle" },
          { label: "WAP URL", key: "wapUrl" },
          { label: "SMS ID", key: "sms_shoot_id" },
          { label: "Sender ID", key: "sender_id" },
          { label: "Espee Deducted", key: "espess_deducted" },
          { label: "Failed Contacts", key: "failed_contacts" },
        ];
        break;
      case "vcard":
        columns = [
          { label: "Recipients", key: "recipients" },
          { label: "First Name", key: "firstName" },
          { label: "Last Name", key: "lastName" },
          { label: "Company", key: "company" },
          { label: "Job Title", key: "jobTitle" },
          { label: "Telephone", key: "telephone" },
          { label: "Email", key: "email" },
          { label: "SMS ID", key: "sms_shoot_id" },
          { label: "Sender ID", key: "sender_id" },
          { label: "Espee Deducted", key: "espess_deducted" },
          { label: "Failed Contacts", key: "failed_contacts" },
        ];
        break;
      default:
        return null;
    }

    return (
      <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.key}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMessages.map((message, index) => (
              <TableRow key={index}>
                {messageType === "text" || messageType === "flash" ? (
                  <>
                    <TableCell
                      className="text-wrap"
                      sx={{
                        wordBreak: "break-word",
                        maxWidth: "30%",
                      }}
                    >
                      <span>{message.contacts}</span>
                    </TableCell>
                    <TableCell
                      className="text-wrap"
                      sx={{
                        wordBreak: "break-word",
                        maxWidth: "30%",
                      }}
                    >
                      <span>{message.msg}</span>
                    </TableCell>
                    <TableCell>{message.status}</TableCell>
                    <TableCell>{message.sms_shoot_id}</TableCell>
                    <TableCell>{message.senderid}</TableCell>
                    <TableCell>{message.espees_deducted}</TableCell>
                    <TableCell
                      className="text-wrap"
                      sx={{
                        wordBreak: "break-word",
                        maxWidth: "30%",
                      }}
                    >
                      <span>{message.failed_contacts}</span>
                    </TableCell>
                  </>
                ) : messageType === "wap" ? (
                  <>
                    <TableCell className="text-wrap" sx={{
                        wordBreak:'break-word',
                        maxWidth:'30%'
                      }}>
                      <span>{message.contacts}</span>
                    </TableCell>
                    <TableCell className="text-wrap"sx={{
                        wordBreak:'break-word',
                        maxWidth:'30%'
                      }}><span>{message.msg}</span></TableCell>
                    <TableCell>{message.wap_title}</TableCell>
                    <TableCell>{message.wap_url}</TableCell>
                    <TableCell>{message.status}</TableCell>
                    <TableCell>{message.sms_shoot_id}</TableCell>
                    <TableCell>{message.senderid}</TableCell>
                    <TableCell>{message.espees_deducted}</TableCell>
                    <TableCell
                      className="text-wrap"
                      sx={{
                        wordBreak: "break-word",
                        maxWidth: "30%",
                      }}
                    >
                      <span>{message.failed_contacts}</span>
                    </TableCell>
                  </>
                ) : messageType === "vcard" ? (
                  <>
                    <TableCell className="text-wrap" sx={{
                        wordBreak:'break-word',
                        maxWidth:'30%'
                      }}>
                      <span>{message.contacts}</span>
                    </TableCell>
                    <TableCell>{message.first_name}</TableCell>
                    <TableCell>{message.last_name}</TableCell>
                    <TableCell>{message.company}</TableCell>
                    <TableCell>{message.job_title}</TableCell>
                    <TableCell>{message.telephone}</TableCell>
                    <TableCell>{message.email}</TableCell>
                    <TableCell>{message.status}</TableCell>
                    <TableCell>{message.sms_shoot_id}</TableCell>
                    <TableCell>{message.senderid}</TableCell>
                    <TableCell>{message.espees_deducted}</TableCell>
                    <TableCell
                      className="text-wrap"
                      sx={{
                        wordBreak: "break-word",
                        maxWidth: "30%",
                      }}
                    >
                      <span>{message.failed_contacts}</span>
                    </TableCell>
                  </>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <section className="w-full md:w-[85%] p-6 mx-auto">
      <div>
        <h2 className="font-semibold text-lg">Sent Messages</h2>
        <p className="text-[#6f7070]">An overview of your sent messages</p>
      </div>
      <Tabs
        value={messageType}
        onChange={handleTabChange}
        sx={{ margin: "10px 0" }}
      >
        <Tab label="Text" value="text" />
        <Tab label="Flash" value="flash" />
        <Tab label="WAP" value="wap" />
        <Tab label="VCard" value="vcard" />
      </Tabs>
      <div>
        {isLoading ? (
          <div className="flex justify-center items-center">
            <CircularProgress />
          </div>
        ) : (
          renderTable()
        )}
      </div>
    </section>
  );
};

export default SentMessages;
