import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import api from "../services/api";
import { toast } from "react-toastify";

const RequestIDModal = ({ open, onClose }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(""); // State for phone number
  const [code, setCode] = useState(""); // State for phone number
  const [codeSent, setCodeSent] = useState(false);
  const [sending, setSending] = useState(false);


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleSendCode = ( ) => {
    setSending(true)
    const formData = new FormData();
    formData.append("own_number", phoneNumber);
    api.campaign.sendTTS(formData)
      .then((res) => {
        toast.success('Code sent')
        setCodeSent(true);
      })
      .catch((err) => {console.log(err);toast.error(err.data?.response.message)}).finally(()=>setSending(false))
  };

  const handleVerifyCode = () => {
    if(codeSent){
      setSending(true)
    }
    const formData = new FormData();
    formData.append("own_number", phoneNumber);
    formData.append("validation_code", code);
    api.campaign.sendTTS(formData)
      .then((res) => {
        toast.success(res.data?.message)
        onClose()
      })
      .catch((err) => console.log(err)).finally(() => setSending(false))
  };

  const handleSubmit = () => {
    if (selectedOption === 'virtual') {
      onClose()
    } else {
      handleVerifyCode()
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{overflow:'scroll'}}
    >
      <Box sx={style} >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Request ID
        </Typography>
        <RadioGroup value={selectedOption} onChange={handleOptionChange}>
          <FormControlLabel
            value="virtual"
            control={<Radio />}
            label="Virtual Number"
          />
          <FormControlLabel
            value="own"
            control={<Radio />}
            label="Own Number"
          />
        </RadioGroup>

        {/* Conditionally render phone number input based on selected option */}
        {selectedOption === 'own' && (
          <>
            <TextField
              id="phoneNumber"
              label={"Own Number (premium rates apply)"}
              placeholder={"Enter own number"}
              fullWidth
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              style={{ marginTop: 20 }}
            />
            {selectedOption ===
              "own" && (
                <div className="mt-3">
                  <Typography>
                    We have to verify the phone number before you can use it as
                    caller iD. Click the 'Send code' button below and we will
                    place a quick call to the number and provide a validation
                    code.
                  </Typography>
                  <Button variant="contained" color="primary" onClick={handleSendCode} disabled={codeSent === true} sx={{margin:'5px 0'}}>{sending ? <CircularProgress sx={{color:'white'}}/> :'Send Code'}</Button>
                  <Box>
                    <Typography>Enter the code in the box below</Typography>
                    <TextField
                      id="code"
                      placeholder={"Enter code here..."}
                      fullWidth
                      value={code}
                      onChange={(e)=>setCode(e.target.value)}
                      style={{ marginTop: 20 }}
                    />
                  </Box>
                </div>
              )}
          </>
        )}
        {selectedOption === 'virtual' && (
          <div>
            <p>To obtain a dedicated virtual phone number for caller ID, please contact customer support @dmplus on kingschat</p>
            <small>Kindly Update your phone number on Kingschat before applying for Virtual Number</small>
          </div>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            !selectedOption ||
            (selectedOption !== "virtual" && selectedOption !== "own")
          }
          style={{ marginTop: 20 }}
        >
          {sending ? <CircularProgress sx={{color:'white'}}/> :'Submit'}
        </Button>
      </Box>
    </Modal>
  );
};

export default RequestIDModal;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
