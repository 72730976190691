import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import { NavLink, Outlet } from "react-router-dom";
import { dashboardMenuItems, admindashboardMenuItems } from "../data";
import { Button, useMediaQuery } from "@mui/material";
import { useUser } from "../Context/UserProvider";
import Announcement from "../Components/Announcement";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  // backgroundImage: `url('https://as1.ftcdn.net/v2/jpg/02/82/17/14/1000_F_282171411_jsMbrCbKtb1cJjOdaKnrNR9lPPuS4nge.jpg')`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  position: "relative",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      // backgroundImage: `url('https://as1.ftcdn.net/v2/jpg/02/82/17/14/1000_F_282171411_jsMbrCbKtb1cJjOdaKnrNR9lPPuS4nge.jpg')`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundColor: "#4bb5ff",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 255, 0.5)", // blue overlay
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      // backgroundImage: `url('https://as1.ftcdn.net/v2/jpg/02/82/17/14/1000_F_282171411_jsMbrCbKtb1cJjOdaKnrNR9lPPuS4nge.jpg')`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundColor: "#4bb5ff",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 255, 0.5)", // blue overlay
    },
  }),
}));

export default function DashboardLayout() {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = React.useState(isLargeScreen);

  React.useEffect(() => {
    setOpen(isLargeScreen);
  }, [isLargeScreen]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { logout, user, espee } = useUser();

  const menuItems =
    user.role === "admin" ? admindashboardMenuItems : dashboardMenuItems;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box>
        <AppBar
          position="fixed"
          open={open}
          sx={{ backgroundColor: "white", color: "#10439F" }}
          >
          {user.role !== "admin" && <Announcement />}
          <Toolbar>
            {!open && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Typography variant="h6" noWrap component="div">
                Text & Voice Dashboard
              </Typography>
              <Box sx={{ display: "flex", gap: "20px", marginLeft: "auto" }}>
                {user.role === "user" && (
                  <Typography variant="p">Account Bal: {espee}</Typography>
                )}
                <div className="hidden md:block">
                  <Typography variant="p" sx={{ marginRight: 2 }}>
                    {user.name}
                  </Typography>
                </div>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer variant="permanent" open={open} className="drawer">
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} sx={{ color: "white" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <div className="px-[3%]" id="menuItems">
          {menuItems.map((menu, index) => (
            <React.Fragment key={index}>
              <p
                className={`p-2 text-white font-bold text-lg ${!open ? "truncate text-ellipsis" : ""
                  }`}
              >
                {menu.title}
              </p>
              <List id="menuItems">
                {menu.items.map((text) => (
                  <ListItem
                    key={text}
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => { user.role !== 'admin' && handleDrawerClose() }}
                  >
                    <NavLink
                      to={text.link}
                      className={({ isActive }) =>
                        isActive ? "active-navlink" : "inactive-navlink"
                      }
                      end
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: "white",
                          }}
                        >
                          {text.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={text.name}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </NavLink>
                  </ListItem>
                ))}
              </List>
              <Divider />
            </React.Fragment>
          ))}
        </div>
        <div className="flex justify-center w-[80%] mx-auto mb-4">
          <Button
            variant="contained"
            fullWidth
            sx={{ backgroundColor: "red" }}
            startIcon={<LogoutIcon />}
            onClick={() => logout()}
          >
            Logout
          </Button>
        </div>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: "#F3F3F3",
          minHeight: "100vh",
          padding: "10px",
        }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
