import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useMediaQuery, Button, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../services/api';
import ReactPaginate from 'react-paginate';

const VoiceReports = () => {
  const [voiceReports, setVoiceReports] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setIsFetching(true)
    api.admin.getVoiceHistory()
      .then(res => {
        setVoiceReports(res.data['Voice-Sms']);
      })
      .catch(err => {
        toast.error("Failed to fetch voice campaign history.");
      }).finally(() => setIsFetching(false))
  }, []);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const pageCount = Math.ceil(voiceReports.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = voiceReports.slice(indexOfFirstItem, indexOfLastItem);

  const handleItems = (e) => {
    setItemsPerPage(e.target.value)
  }

  const handleSpeak = (msg) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(msg);
      speechSynthesis.speak(utterance);
    } else {
      alert('Sorry, your browser does not support text to speech!');
    }
  };

  return (
    <section className="w-full md:w-[95%] p-6">
      <div className='mb-5'>
        <h1 className='text-2xl font-bold '>Voice Reports</h1>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S/N</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Contacts</TableCell>
              <TableCell>Failed Contacts</TableCell>
              <TableCell>No Of Failed Contacts</TableCell>
              <TableCell>No Of Sent Contacts</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Units</TableCell>
              <TableCell>Broadcast ID</TableCell>
              <TableCell>Message Length</TableCell>
              <TableCell>ESPEES Deducted</TableCell>
              <TableCell>Refunded Amount</TableCell>
              <TableCell>Audio File</TableCell>
              {!isMobile && (
                <>
                  <TableCell>Created At</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody className='w-full overflow-x-auto'>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              currentItems.map((report, idx) => (
                <TableRow key={report.id}>
                  <TableCell sx={{ width: '10px' }}>{idx + 1}</TableCell>
                  <TableCell>{report.username}</TableCell>
                  <TableCell sx={{ wordWrap: 'break-word' }}>
                    <p className="text-wrap w-[300px]">{report.contacts}</p>
                  </TableCell>
                  <TableCell sx={{ wordWrap: 'break-word' }}>
                    <p className="text-wrap w-[300px]">{report?.failed_contacts || 'NONE'}</p>
                  </TableCell>
                  <TableCell className="text-wrap w-[10px]">
                    {report.no_of_failed_contacts}
                  </TableCell>
                  <TableCell className="text-wrap w-[10px]">
                    {report.no_of_sent_contacts}
                  </TableCell>
                  <TableCell sx={{ width: '10px' }}>{report.status}</TableCell>
                  <TableCell sx={{ wordWrap: 'break-word' }}>
                    <p className="text-wrap w-[300px]">{report.msg}</p>
                  </TableCell>
                  <TableCell sx={{ width: '10px' }}>{report.units}</TableCell>
                  <TableCell>{report.broadcastId}</TableCell>
                  <TableCell>{report.msgLength}</TableCell>
                  <TableCell>{report.espees_deducted}</TableCell>
                  <TableCell>{report.refunded_amount || 0.00}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      href={report.audioFileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => report.audioFileUrl === null && handleSpeak(report.msg)}
                    >
                      Listen
                    </Button>
                  </TableCell>
                  {!isMobile && (
                    <TableCell>
                      {new Date(report.created_at).toLocaleString()}
                    </TableCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <select
          name="items"
          value={itemsPerPage}
          id="itemsperpage"
          onChange={handleItems}
          className="m-4 border border-[#1977CC]"
        >
          <option value={20}>20</option>
          <option value={40}>40</option>
          <option value={60}>60</option>
        </select>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"flex justify-center gap-2 my-12"}
          previousLinkClassName={
            "rounded-full border px-4 py-2 mr-2 bg-red-500 text-white"
          }
          nextLinkClassName={
            "rounded-full border px-4 py-2 ml-2 bg-asparagus-500 text-[#1977CC]"
          }
          breakClassName={"text-blue-500 cursor-pointer"}
          activeClassName={"bg-blue-500 text-white border rounded-full p-2"}
        />
      </TableContainer>
    </section>
  );
};

export default VoiceReports;
