import React from "react";
import { Typography, Box } from "@mui/material";

const MaintenancePage = ({message}) => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-800">
      <Box className="text-center">
        <Typography variant="h2" className="text-white font-bold mb-4">
          We’ll Be Back Soon!
        </Typography>
        <Typography variant="body1" className="text-gray-300 mb-6">
          {message}.
        </Typography>
        <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-blue-400 mx-auto"></div>
      </Box>
    </div>
  );
};

export default MaintenancePage;
