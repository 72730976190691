import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { CircularProgress, ListItemText, Typography } from "@mui/material";
import kingsChatWebSdk from "kingschat-web-sdk";
import "kingschat-web-sdk/dist/stylesheets/style.min.css";
import { auth } from "../services/api/auth";
import { useUser } from "../Context/UserProvider";
import service from "../services";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../assets/logo-removebg-preview.png";
import api from "../services/api";
import { HashLink as NavHashLink } from 'react-router-hash-link';
import { Email, Phone } from "@mui/icons-material";

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { setUser } = useUser();
  const navigate = useNavigate();

  const loginOptions = {
    scopes: ["send_chat_message"],
    clientId: "ab3fb2cb-7bfe-4378-8925-dbf8d339b5e4",
  };

  const Loader = () => {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1300, // Make sure it's on top of everything else
        }}
      >
        <CircularProgress size={60} thickness={4.5} />
      </Box>
    );
  };

  function loginWithKingsChat() {
    setLoading(true);
    kingsChatWebSdk
      .login(loginOptions)
      .then((authenticationTokenResponse) => {
        const token = authenticationTokenResponse.accessToken;

        if (!token) {
          throw new Error("Token not found in authentication response.");
        }
        // Fetch the profile data using the token
        return fetch("https://connect.kingsch.at/developer/api/profile", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error fetching profile: ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        return auth.login(data).then((res) => {
          service.setTokensLocalStorage(res.data?.access_token);
          api.profile
            .userProfile()
            .then((res) => {
              setLoading(false); // Remove loader
              toast.success("Logged in Successfully");
              setUser(res?.data);
              navigate("/dashboard");
            })
            .catch((res) => console.log(res));
        });
      })
      .catch((error) => {
        setLoading(false); // Remove loader in case of error
        console.error("Error:", error);
        toast.error("Login failed. Please try again.");
      });
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem
          component={NavLink}
          to="/"
          exact
          activeClassName="Mui-selected"
        >
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem
          component={NavHashLink}
          to="#about-us"
          activeClassName="Mui-selected"
        >
          <ListItemText primary="About" />
        </ListItem>
        <ListItem
          component={NavHashLink}
          to="#service"
          activeClassName="Mui-selected"
        >
          <ListItemText primary="Service" />
        </ListItem>
        <ListItem
          component={NavHashLink}
          to="#contact"
          activeClassName="Mui-selected"
        >
          <ListItemText primary="Contact" />
        </ListItem>
      </List>
      <Divider />
      <List sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '80%', margin: '0 auto' }}>
        <Button variant="contained" onClick={loginWithKingsChat}>
          Login with KingsChat
        </Button>
        <Button variant="contained" onClick={loginWithKingsChat}>
          Register
        </Button>
      </List>
    </Box>
  );

  return (
    <>
      {loading && <Loader />}
      <div className='px-2 md:px-[5%] xl:px-[10%] flex flex-wrap items-start w-full text-white bg-[#1977CC]'>
        <IconButton href="mailto:textandvoice@digitalmediaplus.info" color="inherit" aria-label="Email us">
          <Email />
          <Typography variant="body1" component="span" className="ms-2">
            textandvoice@digitalmediaplus.info
          </Typography>
        </IconButton>
        <IconButton href="tel:+2348054629268" color="inherit" aria-label="Call us">
          <Phone />
          <Typography variant="body1" component="span" className="ms-2">
            +234 805 462 9268
          </Typography>
        </IconButton>
      </div>
      <AppBar
        position="sticky"
        sx={{ color: 'white', backgroundColor: 'white' }}
        className='px-2 md:px-[5%] xl:px-[10%]'
      >
        <Toolbar>
          <header className="flex flex-row justify-between items-center w-full">
            <div className="flex items-center">
              <a href="/">
                <img src={Logo} alt="text&voice" className="w-[50px] md:w-[80px]" />
              </a>
              <h2 className="text-[#2C4964] font-extrabold text-[20px] sm:text-[25px] md:text-[33px]">Text&Voice</h2>
            </div>
            {!isMobile && (
              <div style={{ display: 'flex', marginLeft: 'auto', gap: '5px', color: '#2C4964', justifyContent:'flex-end', }}>
                <Button
                  color="inherit"
                  component={NavLink}
                  to="/"
                  exact
                  activeClassName="Mui-selected"

                >
                  Home
                </Button>
                <Button
                  color="inherit"
                  component={NavHashLink}
                  to="#about-us"
                  activeClassName="Mui-selected"
                >
                  About
                </Button>
                <Button
                  color="inherit"
                  component={NavHashLink}
                  to="#service"
                  activeClassName="Mui-selected"
                >
                  Service
                </Button>
                <Button
                  color="inherit"
                  component={NavHashLink}
                  to="#contact"
                  activeClassName="Mui-selected"
                >
                  Contact
                </Button>
                <Button
                  variant="contained"
                  sx={{ color: "white", backgroundColor: "#6495ED" }}
                  disableElevation
                  onClick={loginWithKingsChat}
                >
                  Login with KingsChat
                </Button>
                <Button
                  variant="outlined"
                  sx={{ color: "#10439F", border: "2px solid #10439F" }}
                  onClick={loginWithKingsChat}
                >
                  Register
                </Button>
              </div>
            )}
            {isMobile && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2, color: '#2C4964', fontWeight: '700' }}
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
          </header>
          {isMobile && (
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
              {drawerContent}
            </Drawer>
          )}
        </Toolbar>
      </AppBar>
    </>

  );
};

export default Navbar;
