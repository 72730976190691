import React, { useEffect, useState } from "react";
import api from "../../services/api";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  TextField,
  styled,
  Button,
  Typography,
  CircularProgress, // Import CircularProgress
} from "@mui/material";
import EmptyState from "../../Components/EmptyState";
// import CsvDownloader from "react-csv-downloader";

const DeliveryReports = () => {
  const [reports, setReports] = useState([]);
  const [messageType, setMessageType] = useState("text");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api.campaign
      .getCampaignHistory()
      .then((res) => setReports(res.data?.campaigns))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, []);

  const handleTabChange = (event, newValue) => {
    setMessageType(newValue);
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#10439F"),
    backgroundColor: "#10439F",
    "&:hover": {
      backgroundColor: "#10439F",
    },
  }));

  const parseDate = (dateString) => {
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0); // Reset time to start of the day for accurate comparison
    return date;
  };

  const isWithinDateRange = (dateString) => {
    const date = parseDate(dateString);
    const start = startDate ? parseDate(startDate) : null;
    const end = endDate ? parseDate(endDate) : null;

    if (start && date < start) return false;
    if (end && date > end) return false;
    return true;
  };

  const filteredMessages = reports.filter((message) => {
    return (
      message.type === messageType && isWithinDateRange(message.created_at)
    );
  });

  const columns = {
    text: [
      { label: "Recipients", key: "contacts" },
      { label: "Failed Contacts", key: "failed_contacts" },
      { label: "No Of Failed Contacts", key: "no_of_failed_contacts" },
      { label: "No of Sent Contacts", key: "no_of_sent_contacts" },
      { label: "Message", key: "msg" },
      { label: "Status", key: "status" },
      { label: "SMS ID", key: "sms_shoot_id" },
      { label: "Sender ID", key: "senderid" },
      { label: "Espee Deducted", key: "espees_deducted" },
    ],
    flash: [
      { label: "Recipients", key: "contacts" },
      { label: "Failed Contacts", key: "failed_contacts" },
      { label: "No Of Failed Contacts", key: "no_of_failed_contacts" },
      { label: "No of Sent Contacts", key: "no_of_sent_contacts" },
      { label: "Message", key: "msg" },
      { label: "Status", key: "status" },
      { label: "SMS ID", key: "sms_shoot_id" },
      { label: "Sender ID", key: "senderid" },
      { label: "Espee Deducted", key: "espees_deducted" },
    ],
    wap: [
      { label: "Recipients", key: "contacts" },
      { label: "Failed Contacts", key: "failed_contacts" },
      { label: "No Of Failed Contacts", key: "no_of_failed_contacts" },
      { label: "No of Sent Contacts", key: "no_of_sent_contacts" },
      { label: "Message", key: "msg" },
      { label: "WAP Title", key: "wap_title" },
      { label: "WAP URL", key: "wap_url" },
      { label: "Status", key: "status" },
      { label: "SMS ID", key: "sms_shoot_id" },
      { label: "Sender ID", key: "senderid" },
      { label: "Espee Deducted", key: "espees_deducted" },
    ],
    vcard: [
      { label: "Recipients", key: "contacts" },
      { label: "Failed Contacts", key: "failed_contacts" },
      { label: "No Of Failed Contacts", key: "no_of_failed_contacts" },
      { label: "No of Sent Contacts", key: "no_of_sent_contacts" },
      { label: "First Name", key: "first_name" },
      { label: "Last Name", key: "last_name" },
      { label: "Company", key: "company" },
      { label: "Job Title", key: "job_title" },
      { label: "Telephone", key: "telephone" },
      { label: "Email", key: "email" },
      { label: "Status", key: "status" },
      { label: "SMS ID", key: "sms_shoot_id" },
      { label: "Sender ID", key: "senderid" },
      { label: "Espee Deducted", key: "espees_deducted" },
    ],
  };

  const renderTable = () => {
    if (filteredMessages.length < 1) {
      return (
        <div className="flex justify-center">
          <EmptyState
            to={"/dashboard/compose-sms"}
            stateMessage={"No reports yet"}
            buttonMessage={"Compose message"}
          />
        </div>
      );
    }

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns[messageType].map((column) => (
                <TableCell key={column.key}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMessages.map((message, index) => (
              <TableRow key={index}>
                {columns[messageType].map((column) => (
                  <TableCell className="text-wrap" key={column.key}>{message[column.key]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const downloadCSV = () => {
    const csvData = filteredMessages.map((message) => {
      const data = {};
      columns[messageType].forEach((column) => {
        data[column.label] = message[column.key];
      });
      return data;
    });

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [
        columns[messageType].map((col) => col.label).join(","),
        ...csvData.map((row) => Object.values(row).join(",")),
      ].join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "delivery_reports.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section className="w-full md:w-[85%] p-6 mx-auto">
      <div>
        <h2 className="font-semibold text-lg">Delivery Reports</h2>
        <p className="text-[#6f7070]">An overview of your delivery reports</p>
      </div>
      <div className="shadow-md flex flex-col gap-3 w-full mt-6 md:w-[600px] mx-auto p-3 md:p-6">
        <Typography variant="h6">Download Report</Typography>
        <div className="flex flex-col md:flex-row justify-start gap-5">
          <TextField
            hiddenLabel
            id="start-date"
            variant="outlined"
            type="date"
            fullWidth
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <TextField
            hiddenLabel
            id="end-date"
            variant="outlined"
            type="date"
            fullWidth
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <ColorButton variant="contained" onClick={downloadCSV}>
          Download Reports
        </ColorButton>
      </div>
      <Tabs
        value={messageType}
        onChange={handleTabChange}
        sx={{ margin: "10px 0" }}
      >
        <Tab label="Text" value="text" />
        <Tab label="Flash" value="flash" />
        <Tab label="WAP" value="wap" />
        <Tab label="VCard" value="vcard" />
      </Tabs>
      <div>
        {isLoading ? (
          <div className="flex justify-center items-center">
            <CircularProgress />
          </div>
        ) : (
          renderTable()
        )}
      </div>
    </section>
  );
};

export default DeliveryReports;
