import { Grid, styled, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoCard from "../../Components/InfoCard";
import { useNavigate } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useUser } from "../../Context/UserProvider";
import DraftsIcon from "@mui/icons-material/Drafts";
// import EmailIcon from '@mui/icons-material/Email';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import api from "../../services/api";
import Espee from '../../assets/ESPEE.png'

const Dashboard = () => {
  const navigate = useNavigate();
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#4bb5ff"),
    "&:hover": {
      backgroundColor: "#4bb5ff",
    },
  }));

  const { setEspee, espee, setPrices, user } = useUser();
  const [last,setLast] = useState('00-00-0000')

  useEffect(() => {
    if( user.role === 'user'){
      const fetchPrices = async () => {
        try {
          const smsRes = await api.campaign.getSMSUNITPRICE();
          const voiceRes = await api.campaign.getVoiceUNITPRICE();
          const virtualRes = await api.campaign.getVirtualUNITPRICE();
          const ownRes = await api.campaign.getOwnNumberUNITPRICE();
  
          setPrices({
            smsUnitPrice: smsRes.data['SMS-UNIT-PRICE'][0].value,
            voiceUnitPrice: voiceRes.data['SMS-UNIT-PRICE'][0].value,
            virtualUnitPrice: virtualRes.data['VIRTUAL-UNIT-PRICE'][0].value,
            ownUnitPrice: ownRes.data['OWN-UNIT-PRICE'][0].value,
          });
        } catch (err) {
          console.error("Error getting Unit Price:", err);
        }
      };
  
      fetchPrices();
    }
  }, [setPrices, user.role]);


  useEffect(() => {
    api.campaign
      .getEspeeBalance()
      .then((res) => {
        setEspee(res.data?.['espees-balance']
        )
      })
      .catch((err) => console.log(err));
      api.campaign.getLastSentMessage().then((res) => {
        const formatDate = (timestamp) => {
          const date = new Date(timestamp);
          return date.toISOString().split("T")[0];
        };
        setLast(formatDate(res.data?.last_sent_message_time))
      })
      .catch((err) => console.log(err));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dashboardDetails = [
    {
      icon: <img src={Espee} alt="espee logo" className='w-[24px]'/>,
      title: "Account Balance",
      subtitle: espee,
      button: false,
    },
    // {
    //   icon: <EmailIcon />,
    //   title:'Free SMS',
    //   subtitle: user?.free_sms || 0.0,
    //   button:false
    // },
    {
      icon: <CalendarMonthIcon />,
      title: "Last Message Sent",
      subtitle: last,
      button: false,
    },
    {
      icon: <DraftsIcon />,
      title: "Send Message",
      subtitle: "0.00",
      button: true,
      buttonAction: "/dashboard/compose-sms",
    },
    {
      icon: <AccountBalanceWalletIcon />,
      title: "Fund Your Account",
      subtitle: "0.00",
      button: true,
      buttonAction: "/dashboard/buy-sms-online",
    },
  ];
  return (
    <section className="w-full md:w-[85%] p-6 mx-auto flex justify-center items-center h-full md:h-[75%]">
      <Grid container spacing={2}>
        {dashboardDetails.map((x, y) => {
          return (
            <Grid key={y} item xs={12} sm={6} md={4}>
              <InfoCard
                icon={x.icon}
                title={x.title}
                subtitle={x.subtitle}
                button={
                  x.button && (
                    <ColorButton
                      variant="outlined"
                      endIcon={<KeyboardDoubleArrowRightIcon />}
                      onClick={() => navigate(x.buttonAction)}
                    >
                      Continue
                    </ColorButton>
                  )
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </section>
  );
};

export default Dashboard;
