import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../services/api";

const SetWallet = () => {
  const [wallet, setWallet] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true)
    api.admin.getWalletAddress()
      .then((res) => {
        setWallet(res.data['wallet-Address'][0].value);
      })
      .catch((err) => {
        toast.error("Failed to get Unit Price.");
        console.error("Error getting Unit Price:", err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [])

  const handleInputChange = (e) => {
    setWallet(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("wallet_address", wallet);

    api.admin.setWalletAddress(formData)
      .then((res) => {
        console.log(res)
        toast.success("Wallet Address updated successfully!");
        setWallet("");
      })
      .catch((err) => {
        toast.error("Failed to Wallet Address.");
        console.error("Error updating wallet address:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 400,
        margin: "0 auto",
        padding: 2,
        boxShadow: 3,
        borderRadius: 1,
        backgroundColor: "white",
      }}
    >
      <Typography variant="h5" component="h2" gutterBottom>
        Set Wallet Address
      </Typography>
      {isFetching ? <CircularProgress /> :<TextField
        label="Wallet Address"
        variant="outlined"
        name="wallet"
        value={wallet}
        onChange={handleInputChange}
        fullWidth
        required
        sx={{ marginBottom: 2 }}
      />}
      <Button type="submit" variant="contained" color="primary" disabled={loading}>
        {loading ? <CircularProgress size={24} /> : "Submit"}
      </Button>
    </Box>
  );
};

export default SetWallet