import { createContext, useContext, useState, useEffect } from "react";

const UserContext = createContext({});

const UserProvider = ({ children }) => {
  const storedUser = JSON.parse(sessionStorage.getItem("user")) || {};
  const storedBalance = JSON.parse(sessionStorage.getItem("balance")) || "";
  const storedPrices = JSON.parse(sessionStorage.getItem("prices")) || {
    smsUnitPrice: 0.0,
    voiceUnitPrice: 0.0,
    virtualUnitPrice: 0.0,
    ownUnitPrice: 0.0,
  };
  const [user, setUser] = useState(storedUser);
  const [espee, setEspee] = useState(storedBalance);
  const [prices, setPrices] = useState(storedPrices);

  useEffect(() => {
    sessionStorage.setItem("user", JSON.stringify(user));
    sessionStorage.setItem("balance", JSON.stringify(espee));
    sessionStorage.setItem("prices", JSON.stringify(prices));
  }, [user, espee, prices]);

  const logout = () => {
    window.location.replace("/");
    setUser({});
    setEspee("");
    sessionStorage.clear();
  };

  return (
    <UserContext.Provider
      value={{ user, setUser, logout, espee, setEspee, prices, setPrices }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the user context
const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
