import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import GroupIcon from '@mui/icons-material/Group';
import SpeedIcon from '@mui/icons-material/Speed';
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import KeyIcon from '@mui/icons-material/Key';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import OutboxIcon from '@mui/icons-material/Outbox';
import PortraitIcon from '@mui/icons-material/Portrait';
import RestoreIcon from '@mui/icons-material/Restore';
import { Email } from "@mui/icons-material";
import ContactsIcon from '@mui/icons-material/Contacts';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import SettingsIcon from '@mui/icons-material/Settings';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import test1 from './assets/testimonials-1.jpg'
import test2 from './assets/testimonials-2.jpg'
import test3 from './assets/testimonials-3.jpg'
import test4 from './assets/testimonials-4.jpg'
import test5 from './assets/testimonials-5.jpg'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ApprovalIcon from '@mui/icons-material/Approval';



export const reasons = [
  {
    icon: (<i class="fas fa-laptop"></i>),
    title: "Easy-to-Use Web Interface",
    summary: "Effortlessly manage your marketing campaigns and internal communications with our intuitive online platform.",
  },
  {
    icon: (<i class="fas fa-clock"></i>),
    title: "Save Time & Send in Bulk",
    summary: "Streamline your messaging by sending in bulk, whether text or voice thereby eliminating the hassle of individual sends.",
  },
  {
    icon: (<i class="fas fa-shield-alt"></i>),
    title: "Fast, Secure & Reliable",
    summary: "Ensure your messages are delivered swiftly and securely through our premium routes.",
  },
  {
    icon: (<i class="fas fa-calendar-check"></i>),
    title: "Schedule and Send Later",
    summary: "Plan and schedule messages for future delivery with ease, and set up multiple schedules to automate your communications.",
  },
  {
    icon: (<i class="fas fa-phone"></i>),
    title: "For ROBOCALLS (Voice sms)",
    summary: "Give your sms a voice. Send your mp3 voice recordings or use our text to speech feature where your typewritten message is converted to voice and sent to your selected contacts.",
  },
  {
    icon: (<i class="fas fa-users"></i>),
    title: "Unlimited SMS Recipients",
    summary: "Reach as many recipients as you need without limits, only constrained by your available credit..",
  }
];


export const dashboardMenuItems = [
  {
    title: 'Message', items: [
      { name: 'Dashboard', link: '/dashboard', icon: <SpeedIcon /> },
      { name: 'SMS Campaign', link: '/dashboard/compose-sms', icon: <MailIcon /> },
      // { name: 'Personalized SMS', link: '/dashboard/personalized-sms', icon: <SmsIcon /> },
      // { name: 'Scheduled SMS', link: '/dashboard/scheduled-sms', icon: <UpcomingIcon /> },
      { name: 'Sent Messages', link: '/dashboard/sent-messages', icon: <MarkEmailReadIcon /> },
      { name: 'Voice Message', link: '/dashboard/text-to-speech', icon: <KeyboardVoiceIcon /> },
      { name: 'Voice Message Reports', link: '/dashboard/voice-reports', icon: <RecordVoiceOverIcon /> },
      { name: 'Delivery Reports', link: '/dashboard/delivery-reports', icon: <SummarizeIcon /> },
    ]
  },
  {
    title: 'Sender ID', items: [
      { name: 'Request Sender ID', link: '/dashboard/request-sender-id', icon: <OutboxIcon /> },
    ]
  },
  {
    title: 'Credit', items: [
      { name: 'Fund Your Acct', link: '/dashboard/buy-sms-online', icon: <CreditCardIcon /> },
      { name: 'Pricing', link: '/dashboard/pricing', icon: <AttachMoneyIcon /> },
    ]
  },
  {
    title: 'Contacts', items: [
      { name: 'My Phonebook', link: '/dashboard/phonebook', icon: <ContactPageIcon /> },
      { name: 'Upload Contacts', link: '/dashboard/upload-contacts', icon: <UploadFileIcon /> },
    ]
  },
  {
    title: 'Profile', items: [
      { name: 'Profile Summary', link: '/dashboard/profile-summary', icon: <PortraitIcon /> },
      { name: 'Transaction History', link: '/dashboard/transaction-history', icon: <RestoreIcon /> },
    ]
  }
];


export const admindashboardMenuItems = [
  {
    title: 'Admin', items: [
      { name: 'Profile Summary', link: '/dashboard/admin', icon: <PortraitIcon /> },
      { name: 'Maintainance Mode', link: '/dashboard/admin/maintainance', icon: <SettingsIcon /> },
      { name: 'Wallet Address', link: '/dashboard/admin/set-wallet-address', icon: <MailIcon /> },
      { name: 'Manage Announcement', link: '/dashboard/admin/announcement', icon: <AnnouncementIcon /> },
      { name: 'Set Contact Address', link: '/dashboard/admin/set-contact-address', icon: <ContactsIcon /> },
      { name: 'Set Contact Email', link: '/dashboard/admin/set-contact-email', icon: <Email /> },
      { name: 'Set Contact Phone', link: '/dashboard/admin/set-contact-phone', icon: <LocalPhoneIcon /> },
    ]
  },
  {
    title: 'Users', items: [
      { name: 'Manage Users', link: '/dashboard/admin/manage-users', icon: <GroupIcon /> },
      // { name: 'Message Users', link: '/dashboard/admin/message-users', icon: <MessageIcon /> },
      // { name: 'Assign ESPEES', link: '/dashboard/admin/assign-espees', icon: <AssignmentIndIcon /> },
      { name: 'Approve Sender ID', link: '/dashboard/admin/approve-sender-id', icon: <ApprovalIcon /> },
    ]
  },
  {
    title: 'SMS', items: [
      { name: 'Set Voice Unit Price', link: '/dashboard/admin/set-voice-unit-price', icon: <PriceChangeIcon /> },
      { name: 'Set SMS Unit Price', link: '/dashboard/admin/set-sms-unit-price', icon: <PriceChangeIcon /> },
      { name: 'Set SMS API Key', link: '/dashboard/admin/set-sms-api-key', icon: <KeyIcon /> },
      { name: 'Set ESPEE API Key', link: '/dashboard/admin/set-espee-api-key', icon: <VpnKeyIcon /> },
    ]
  },
  {
    title: 'Pricing Write up', items: [
      { name: 'Set Pricing ', link: '/dashboard/admin/set-pricing', icon: <PriceChangeIcon /> },
    ]
  },
];



export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const faqs = [
  {
    question: 'How does your messaging service work?',
    answer: 'Our platform allows you to send bulk text messages and voice calls efficiently. Simply upload your contact list, compose your message, and choose your delivery options. Our system will handle the rest, ensuring your messages are sent quickly and reliably.'
  },
  {
    question: 'What is the maximum number of messages I can send at once?',
    answer: "You can send messages to an unlimited number of recipients, constrained only by your available credits. Whether you're reaching hundreds or thousands of recipients, our system can handle it seamlessly."
  },
  {
    question: 'How secure is your messaging service?',
    answer: 'We prioritize your data security. All messages are transmitted via encrypted channels, and we use premium routes to ensure reliability and confidentiality. Your data is protected against unauthorized access.'
  },
  {
    question: 'Can I schedule messages to be sent at a later time?',
    answer: 'Yes, our platform allows you to schedule messages for future delivery. You can set up multiple schedules to automate your communication strategy and ensure your messages reach recipients at the optimal time.'
  },
  {
    question: 'What formats can I use to upload contacts?',
    answer: 'You can upload your contact lists in .csv or .txt formats. This makes it easy to manage and import large volumes of contacts quickly into our system.'
  },
  {
    question: 'How do I get started with your service?',
    answer: 'To get started, sign up here or log in with kingschat and top up your account with espees. Once your account is set up, you can begin sending messages and managing your campaigns through our user-friendly web interface.'
  },
  {
    question: 'I do not have an Espees wallet, is there any alternative means to pay?',
    answer: (
      <>
        We only accept Espees here. You can download your Espees wallet from{' '}
        <a href="https://lwappstore.com" className="text-blue-500 underline">
          here
        </a>.
      </>
    ),
  },
]

export const testimonials = [
  {
    name: 'Chinedu Okafor',
    title: 'Marketing Manager',
    rating: 5,
    image: test1,
    text: 'TEXTANDVOICE.NET has revolutionized our marketing campaigns. The bulk messaging feature is incredibly efficient and reliable, saving us time and enhancing our outreach'
  },
  {
    name: 'Zainab Bello',
    title: 'Customer Service Coordinator',
    rating: 4,
    image: test2,
    text: 'We rely on TEXTANDVOICE.NET for our daily notifications and updates. The scheduling option and high delivery speed ensure our messages reach clients exactly when needed.'
  },
  {
    name: 'Emeka Nwosu',
    title: 'Store Owner',
    rating: 4,
    image: test3,
    text: 'As a small business owner, TEXTANDVOICE.NET has been a game changer. The ease of use and bulk SMS capabilities have greatly simplified how we communicate with our customers.'
  },
  {
    name: 'Fatima Ibrahim',
    title: 'NGO Program Director',
    rating: 4,
    image: test4,
    text: "Our NGO uses TEXTANDVOICE.NET for sending crucial updates to our volunteers. The platform's reliability and excellent customer support make it an invaluable tool for our operations."
  },
  {
    name: 'Olusegun Adeyemi',
    title: 'IT Consultan',
    rating: 4,
    image: test5,
    text: "TEXTANDVOICE.NET offers top-notch security and fast delivery. It's been a reliable solution for managing communications and automating messages for various projects."
  },
];