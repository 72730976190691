import React, { useEffect, useState } from "react";
import {
  Switch,
  FormControlLabel,
  Typography,
  Button,
  Box,
  TextField,
} from "@mui/material";
import api from "../../../services/api";
import { toast } from "react-toastify";

const AdminMaintenanceToggle = () => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [message, setMessage] = useState(""); // State for the message

  useEffect(() => {
    api.admin
      .getMaintenanceStatus()
      .then((res) => {
        const value = res.data.maintenance_mode[0].value;

        const parsedValue = JSON.parse(value);

        if (parsedValue.maintenance_mode === "1") {
          setIsMaintenanceMode(true);
        }
      })
      .catch(console.error);
  }, []);

  const handleToggle = () => {
    const formData = new FormData();

    if (!isMaintenanceMode) {
      formData.append("message", message);
      formData.append("maintenance_mode", 1);

      api.admin
        .maintenance("enable", formData)
        .then((res) => {
          toast.success("The website is currently in maintenance mode.");
          setIsMaintenanceMode(true);
        })
        .catch(console.error);
    } else {
      formData.append("maintenance_mode", 0);

      api.admin
        .maintenance("disable", formData)
        .then((res) => {
          toast.success("The website is live for all users.");
          setIsMaintenanceMode(false);
        })
        .catch(console.error);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <Box className="bg-white p-6 rounded-lg shadow-md max-w-lg w-full text-center flex flex-col gap-4">
        <Typography variant="h4" className="mb-4 text-gray-800">
          Website Maintenance
        </Typography>
        <Typography variant="body1" className="mb-6 text-gray-600">
          {isMaintenanceMode
            ? "The website is currently in maintenance mode."
            : "The website is live for all users."}
        </Typography>

        {/* Only show the message input when enabling maintenance mode */}
        {!isMaintenanceMode && (
          <TextField
            label="Message (Required)"
            variant="outlined"
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            multiline
            rows={3}
            placeholder="Enter a message for the maintenance mode change"
          />
        )}

        <FormControlLabel
          control={
            <Switch
              checked={isMaintenanceMode}
              onChange={handleToggle}
              color="primary"
            />
          }
          label="Toggle Maintenance Mode"
        />
        <Button
          variant="contained"
          color="primary"
          className="mt-4 bg-blue-600"
          onClick={handleToggle}
        >
          {isMaintenanceMode
            ? "Turn Off Maintenance Mode"
            : "Turn On Maintenance Mode"}
        </Button>
      </Box>
    </div>
  );
};

export default AdminMaintenanceToggle;
