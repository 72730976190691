/* eslint-disable no-undef */
const baseUrl = "https://sms.itl.ng";

const routes = {
  url: () => `${baseUrl}/api`,
  register: () => `${routes.url()}/register`,
  login: () => `${routes.url()}/kingschat-login`,
  'admin-login': () => `${baseUrl}/admin/admin-login`,
  'change-password': () => `${routes.url()}/change-password`,
  'my-profile': () => `${routes.url()}/user-profile`,
  'update-profile': () => `${routes.url()}/edit-profile`,
  'get-sms-unit-price': () => `${routes.url()}/get-sms-unit-price`,
  'get-voice-unit-price': () => `${routes.url()}/get-voice-unit-price`,
  'get-virtual-price': () => `${routes.url()}/get-virtual-price`,
  'get-own-number-unit-price': () => `${routes.url()}/get-own-price`,
  'get-announcement': () => `${routes.url()}/get/announcement`,
  campaign: {
    'send-sms': (smsType) => `${routes.url()}/send-${smsType}-sms`,
    'confirm-sms': (smsType) => `${routes.url()}/confirm-send-${smsType}-sms`,
    'schedule-sms': (smsType) => `${routes.url()}/schedule-${smsType}-sms`,
    'confirm-schedule-sms': (smsType) => `${routes.url()}/confirm-schedule-${smsType}-sms`,
    'get-sent-messages': () => `${routes.url()}/get-sent-messages`,
    'get-failed-messages': () => `${routes.url()}/get-failed-messages`,
    'request-senderId': () => `${routes.url()}/request-senderid`,
    'get-senderId': () => `${routes.url()}/get-sender-id`,
    'get-campaignHistory': () => `${routes.url()}/get-campaign-history`,
    'get-transactionHistory': () => `${routes.url()}/get-transaction-history`,
    'get-espee-balance': () => `${routes.url()}/get-espee-balance`,
    'get-last-sent-message': () => `${routes.url()}/get-last-sent-message`,
    'send-voice-message': () => `${routes.url()}/voice-call`,
    'get-voice-records': () => `${routes.url()}/get-voice-records`,
    'get-virtual-number': () => `${routes.url()}/get-virtual-number`,
    'get-own-number': () => `${routes.url()}/get-own-number`,
    'request-virtual-number': () => `${routes.url()}/request-virtual-number`,
    'get-all-voice-history': () => `${routes.url()}/get-sent-voice-sms`
  },
  contacts: {
    'create-contact-within-a-group': (id) => `${routes.url()}/create/contact/group/${id}`,
    'update-contact': (id) => `${routes.url()}/update/contact/${id}`,
    'delete-contact': (id) => `${routes.url()}/delete/contact/${id}`,
    'get-contact': () => `${routes.url()}/get/contact`,
    'get-contact-for-single-group': (id) => `${routes.url()}/get/group/contacts/${id}`,

    'group-contact': () => `${routes.url()}/group/contact`,

    'create-contact-group': () => `${routes.url()}/create/group`,
    'delete-contact-group': (id) => `${routes.url()}/delete/group/${id}`,
    'update-contact-group': (id) => `${routes.url()}/update/group/${id}`,
    'get-contact-group': () => `${routes.url()}/get/group`,

    'import-contact-to-old-group': (id) => `${routes.url()}/import/contact/group/${id}`,
    'import-contact-to-new-group': () => `${routes.url()}/import/contacts/new/group`,
  },
  payments: {
    'make-payment': () => `${routes.url()}/payment-initiate`,
    'confirm-payment': () => `${routes.url()}/payment-confirm`,
  },
  admin: {
    'send-sms-toUser': () => `${baseUrl}/admin/admin-send-sms-user`,
    'get-announcement': () => `${baseUrl}/admin/get-annoucement`,
    'make-announcement': () => `${baseUrl}/admin/manage-annoucement`,
    'get-all-sender-ids': () => `${baseUrl}/admin/get-sender-ids`,
    'approve-sender-id': (id) => `${baseUrl}/admin/approve-senderid/${id}`,
    'disapprove-senderid': (id) => `${baseUrl}/admin/disapprove-senderid/${id}`,
    'delete-virtual-number': (id) => `${baseUrl}/admin/delete-virtual-number/${id}`,
    'get-transactionHistory': () => `${baseUrl}/admin/get-all-transactions`,
    'assign-espee': (id) => `${baseUrl}/admin/assign-espee/${id}`,
    'approve-virtual-number': (id) => `${baseUrl}/admin/approve-virtual-number/${id}`,
    'set-sms-api-key': () => `${baseUrl}/admin/set-sms-api-key`,
    'set-sms-unit-price': () => `${baseUrl}/admin/set-sms-unit-price`,
    'set-voice-unit-price': () => `${baseUrl}/admin/set-voice-unit-price`,
    'set-virtual-unit-price': () => `${baseUrl}/admin/set-virtual-unit-price`,
    'set-own-number-unit-price': () => `${baseUrl}/admin/set-own-number-unit-price`,
    'set-espee-api-key': () => `${baseUrl}/admin/set-espee-api-key`,
    'set-wallet-address': () => `${baseUrl}/admin/set-wallet-address`,

    'get-sms-api-key': () => `${baseUrl}/admin/get-sms-api-key`,
    'get-sms-unit-price': () => `${baseUrl}/admin/get-sms-unit-price`,
    'get-voice-unit-price': () => `${baseUrl}/admin/get-voice-unit-price`,
    'get-virtual-price': () => `${baseUrl}/admin/get-virtual-price`,
    'get-own-number-unit-price': () => `${baseUrl}/admin/get-own-price`,
    'get-espee-api-key': () => `${baseUrl}/admin/get-espee-api-key`,
    'get-wallet-address': () => `${baseUrl}/admin/get-wallet-address`,
    'get-virtual-numbers': () => `${baseUrl}/admin/get-virtual-numbers`,
    'get-own-numbers': () => `${baseUrl}/admin/get-own-numbers`,

    'set-contact-address': () => `${baseUrl}/admin/contact-address`,
    'get-contact-address': () => `${baseUrl}/admin/get-contact-address`,

    'set-contact-phone': () => `${baseUrl}/admin/contact-phone`,
    'set-admin-phone': () => `${baseUrl}/admin/set-admin-number`,
    'get-contact-phone': () => `${baseUrl}/admin/get-contact-phone`,
    'get-admin-phone': () => `${baseUrl}/admin/get-admin-number`,

    'set-contact-email': () => `${baseUrl}/admin/contact-email`,
    'get-contact-email': () => `${baseUrl}/admin/get-contact-email`,

    'set-about-us': () => `${baseUrl}/admin/about-us`,
    'get-about-us': () => `${baseUrl}/admin/get-about-us`,

    'get-all-active-users': () => `${baseUrl}/admin/get-all-active-users`,
    'get-all-inactive-users': () => `${baseUrl}/admin/get-all-inactive-users`,
    'activate-user': (id) => `${baseUrl}/admin/activate-user/${id}`,
    'deactivate-user': (id) => `${baseUrl}/admin/deactivate-user/${id}`,

    'get-all-camapaign-history': () => `${baseUrl}/admin/get-all-camapaign-history`,
    'get-all-voice-history': () => `${baseUrl}/admin/get-all-sent-voice-sms`,

    'get-single-user':(id)=>`${baseUrl}/admin/get-user-details/${id}`,
    'delete-user':(id)=>`${baseUrl}/admin/delete-user/${id}`,

    'maintenance': (type)=>`${baseUrl}/admin/${type}-maintenance-mode`,
    'get-maintenance-status': ()=>`${baseUrl}/admin/get-maintenance-status`
  }
};

export default routes;
