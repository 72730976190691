import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import api from "../../services/api";
import { toast } from "react-toastify";
import { useUser } from "../../Context/UserProvider";
import { useLocation } from "react-router-dom";

const BuySP = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const success = queryParams.get('success');


  const { setEspee, user } = useUser();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    price: "",
    narration: "",
    product_sku: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const Loader = () => {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1300,
        }}
      >
        <CircularProgress size={60} thickness={4.5} />
      </Box>
    );
  };

  useEffect(() => {
    if (success) {
      setLoading(true)
      const formRef = new FormData();
      const paymentRef = sessionStorage.getItem("paymentRef");
      formRef.append("payment_ref", paymentRef);
      api.payments
        .confirmPayment(formRef)
        .then((confirmRes) => {
          toast.success(confirmRes.data?.message);
          setFormValues({
            price: "",
            narration: "",
            product_sku: "",
          })
          sessionStorage.removeItem("paymentRef");
          api.campaign
            .getEspeeBalance()
            .then((res) => {
              setEspee(res.data?.["espees-balance"]);
            })
            .catch((err) => console.log(err));
        })
        .catch((confirmErr) => {
          console.log("Error confirming payment:", confirmErr);
          toast.error(confirmErr.response.data?.message);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [setEspee, success])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.status === 'inactive') {
      toast.error('Please Contact Admin to activate your account')
      setLoading(false)
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("price", formValues.price);
    formData.append("narration", formValues.narration);
    formData.append("product_sku", formValues.product_sku);

    api.payments
      .initiatePayment(formData)
      .then((res) => {
        const ref = res.data["payment-ref"];
        const url = res.data?.payment_url;
        sessionStorage.setItem("paymentRef", ref);

        // Use window.open to open the payment link in a new tab
        window.location.href = url;
      })
      .catch((err) => {
        console.log("Error initiating payment:", err);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading && <Loader />}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          maxWidth: 400,
          margin: "0 auto",
          padding: 2,
          boxShadow: 3,
          borderRadius: 1,
          backgroundColor: "white",
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          Fund your Account with ESPEES
        </Typography>
        <TextField
          label="Amount in ESPEES"
          variant="outlined"
          name="price"
          value={formValues.price}
          onChange={handleInputChange}
          fullWidth
          required
        />
        <TextField
          label="Narration"
          variant="outlined"
          name="narration"
          value={formValues.narration}
          onChange={handleInputChange}
          fullWidth
          required
          multiline
          rows={4}
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Box>

      <div className="flex justify-center items-center my-3">
        <Box>
          <Typography variant="p" component="p" gutterBottom>
            We only accept ESPEES here. Download your espee wallet from
            <a className="underline" href="https://lwappstore.com" target="_black">
              {" "}here{" "}
            </a>
            if you do not have one.
          </Typography>
          <Typography variant="p" component="p" gutterBottom sx={{textAlign:'center'}}>
            To buy ESPEES, click
            <a className="underline" href="https://dmplusng.com" target="_black">
              {" "}here{" "}
            </a>
          </Typography>
        </Box>
      </div>
    </>
  );
};

export default BuySP;
