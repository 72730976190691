import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import api from "../../services/api";
import Loader from "../../Components/Loader";
import { toast } from "react-toastify";
import { useUser } from "../../Context/UserProvider";
import PastRecordingsModal from "../../Components/PastRecordings";
import RequestIDModal from "../../Components/RequestIDModal";

const TextToSpeech = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [messageType, setMessageType] = useState("");
  const [sendTime, setSendTime] = useState("NOW");
  const [recordings, setRecordings] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRequestIDModalOpen, setIsRequestIDModalOpen] = useState(false);
  const [virtualNumbers, setvirtualNumbers] = useState([]);
  const [ownNumbers, setOwnNumbers] = useState([]);
  const [campaign, setCampaign] = useState({
    senderID: "",
    ttsMsg: "",
    ttsVoice: "",
    audioFileUrl: null,
    past_audioFileUrl: null,
    from: "",
    recipients: [],
    startTime: "",
    own_number: "",
    virtual_number: "",
  });

  function UnixTime(date) {
    const dateObject = new Date(date);
    const unixTimestamp = Math.floor(dateObject.getTime() / 1000);
    return unixTimestamp;
  }

  const { setEspee, user } = useUser();

  useEffect(() => {
    setIsLoading(true);
    api.contacts
      .getGroup()
      .then((res) => {
        setAllGroups(res.data?.groups);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
    api.campaign
      .getVirtualNumber()
      .then((res) => {
        const approvedNumbers = res.data["virtual-Number"].filter(
          (num) => num.status === "approved"
        );
        const taggedVirtualNumbers = approvedNumbers.map((v) => ({
          ...v,
          type: "virtualNumber",
        }));
        setvirtualNumbers(taggedVirtualNumbers);
      })
      .catch((err) => console.log(err));
    api.campaign
      .getOwnNumber()
      .then((res) => {
        const taggedOwnNumbers = res.data["Own-Number"].map((v) => ({
          ...v,
          type: "ownNumber",
        }));
        setOwnNumbers(taggedOwnNumbers);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (messageType === "past") {
      setIsLoading(true);
      api.campaign
        .getVoiceSMSRecords()
        .then((res) => {
          setIsLoading(false);
          setIsModalOpen(true);
          setRecordings(res.data?.audioUrls);
        })
        .catch((res) => console.log(res));
    }
  }, [messageType]);

  const handleCampaignChange = (field, value) => {
    const [type, selectedValue] = value.split("_");

    if (field === "senderID") {
      if (type === "virtualNumber") {
        setCampaign((prevState) => ({
          ...prevState,
          senderID: "",
          virtual_number: selectedValue,
          own_number: "",
        }));
      } else if (type === "ownNumber") {
        setCampaign((prevState) => ({
          ...prevState,
          senderID: "",
          virtual_number: "",
          own_number: selectedValue,
        }));
      } else {
        // Default senderID
        setCampaign((prevState) => ({
          ...prevState,
          senderID: selectedValue,
          virtual_number: "",
          own_number: "",
        }));
      }
    } else {
      setCampaign((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };

  const handleRecipientsChange = (value) => {
    setCampaign({ ...campaign, recipients: value.split(",") });
  };

  const handleGroupToggle = (groupId) => {
    const currentIndex = selectedGroups.indexOf(groupId);
    const newSelectedGroups = [...selectedGroups];

    if (currentIndex === -1) {
      newSelectedGroups.push(groupId);
    } else {
      newSelectedGroups.splice(currentIndex, 1);
    }

    setSelectedGroups(newSelectedGroups);
    Promise.all(
      newSelectedGroups.map((groupId) =>
        api.contacts
          .getContactForSingleGroup(groupId)
          .then((res) => res.data.contacts.map((contact) => contact.phone))
      )
    ).then((phoneNumbersArray) => {
      const newRecipients = phoneNumbersArray.flat();
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        recipients: [...prevCampaign.recipients, ...newRecipients],
      }));
    });
  };

  const sendTTS = () => {
    setIsSending(true);
    if (user.status === "inactive") {
      toast.error("Please Contact Admin to activate your account");
      setIsSending(false);
      return;
    }
    if (messageType === "TTS" && !campaign.ttsMsg) {
      toast.error("Voice Message is required");
      setIsSending(false);
      return;
    }
    if (messageType === "voice" && !campaign.audioFileUrl) {
      toast.error("Voice is required");
      setIsSending(false);
      return;
    }
    if (messageType === "past" && !campaign.past_audioFileUrl) {
      toast.error("Past audio file is required");
      setIsSending(false);
      return;
    }
    if (campaign.recipients.length === 0) {
      toast.error("Recipients are required");
      setIsSending(false);
      return;
    }
    const formData = new FormData();

    formData.append("defaultId", campaign.senderID);
    formData.append("ttsMsg", campaign.ttsMsg);
    formData.append("ttsVoice", campaign.ttsVoice);
    formData.append("audioFile", campaign.audioFileUrl);
    formData.append("audioUrl", campaign.past_audioFileUrl);
    formData.append("contacts", campaign.recipients);

    if (sendTime === "NOW") {
      formData.append("startTime", campaign.startTime);
    } else {
      formData.append("startTime", UnixTime(campaign.startTime));
    }
    formData.append("own_number", campaign.own_number);
    formData.append("virtual_number", campaign.virtual_number);
    
    api.campaign
      .sendTTS(formData)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Voice SMS Sent");
          api.campaign
            .getEspeeBalance()
            .then((res) => {
              setEspee(res.data?.["espees-balance"]);
            })
            .catch((err) => console.log(err));
          setSelectedGroups([]);
          setMessageType("");
          setCampaign({
            senderID: "",
            ttsMsg: "",
            ttsVoice: "",
            audioFileUrl: "",
            from: "",
            recipients: [],
            startTime: "",
            own_number: "",
            virtual_number: "",
          });
        }
      })
      .catch((res) => toast.error(res.response.data?.message))
      .finally(() => setIsSending(false));
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    padding: "10px 0",
    "&:hover": {
      backgroundColor: "#4bb5ff",
      color: "white",
    },
  }));

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "audio/mpeg") {
      setCampaign((prev) => ({ ...prev, audioFileUrl: file }));
    } else {
      alert("Please select a valid MP3 file.");
    }
  };

  const handleRecordingSelect = (url) => {
    setCampaign((prev) => ({ ...prev, past_audioFileUrl: url }));
    setIsModalOpen(false);
  };

  const renderAudioControl = () => {
    const baseUrl = 'https://sms.itl.ng/public/upload/audio/';
    const fileName = campaign.past_audioFileUrl.replace(baseUrl, '')
      return (
        <Box mt={2}>
          <Typography variant="body1">
            Selected Recording: {fileName}
          </Typography>
          <audio controls src={campaign.past_audioFileUrl} />
        </Box>
      );

  };

  const closeModal = () => {
    setIsModalOpen(false);
    setMessageType("");
  };

  return (
    <section className="composeSMS">
      <div className="w-full md:w-[65] lg:w-[70%] shadow-lg px-[10px] py-[20px]">
        <Container>
          <div className="flex flex-col gap-3 my-4">
            <div className="flex gap-3">
              <FormControl fullWidth>
                <InputLabel id="senderID-select">Sender ID</InputLabel>
                <Select
                  labelId="senderID-select"
                  id="senderID"
                  label="Sender ID"
                  placeholder="Sender ID"
                  value={
                    campaign.senderID
                      ? `defaultSenderID_${campaign.senderID}`
                      : campaign.virtual_number
                      ? `virtualNumber_${campaign.virtual_number}`
                      : campaign.own_number
                      ? `ownNumber_${campaign.own_number}`
                      : ""
                  }
                  onChange={(e) =>
                    handleCampaignChange("senderID", e.target.value)
                  }
                  sx={{ padding: "0 10px" }}
                  renderValue={(selected) => selected.split("_")[1]} // Display only the value part
                >
                  <MenuItem value={`defaultSenderID_07001237392`}>
                    Default Sender ID
                  </MenuItem>
                  <MenuItem value="" disabled>
                    Virtual Numbers
                  </MenuItem>
                  {virtualNumbers.length > 0 &&
                    virtualNumbers.map((v) => (
                      <MenuItem
                        key={v.id}
                        value={`virtualNumber_${v.virtual_num}`}
                      >
                        {v.virtual_num}
                      </MenuItem>
                    ))}
                  <MenuItem value="" disabled>
                    Own Numbers
                  </MenuItem>
                  {ownNumbers.length > 0 &&
                    ownNumbers.map((v) => (
                      <MenuItem
                        key={v.id}
                        value={`ownNumber_${v.phone_number}`}
                      >
                        {v.phone_number}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <ColorButton
                variant="contained"
                onClick={() => setIsRequestIDModalOpen(true)}
                sx={{ padding: "1px 5px" }}
              >
                Request ID
              </ColorButton>
            </div>
            <TextField
              id="recipients"
              label="Recipients"
              multiline
              rows={5}
              placeholder="Numbers eg(801234567,814325679)"
              value={campaign.recipients.join(",")}
              onChange={(e) => handleRecipientsChange(e.target.value)}
            />
            <FormControl fullWidth>
              <InputLabel id="message-type-select">Message Type</InputLabel>
              <Select
                labelId="message-type-select"
                id="message-type"
                value={messageType}
                label="Message Type"
                onChange={(e) => setMessageType(e.target.value)}
              >
                <MenuItem value={""}>Select a Type</MenuItem>
                <MenuItem value={"TTS"}>Text To Speech</MenuItem>
                <MenuItem value={"voice"}>Audio Recording</MenuItem>
                <MenuItem value={"past"}>Past Message Recordings</MenuItem>
              </Select>
            </FormControl>
            {messageType === "TTS" && (
              <div>
                <TextField
                  id="ttsMsg"
                  required
                  label="Voice Message"
                  multiline
                  rows={5}
                  placeholder="Your message here..."
                  fullWidth
                  value={campaign.ttsMsg}
                  onChange={(e) =>
                    handleCampaignChange("ttsMsg", e.target.value)
                  }
                />
              </div>
            )}
            {messageType === "voice" && (
              <Box>
                <input
                  id="audioFileInput"
                  type="file"
                  accept="audio/mpeg"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label htmlFor="audioFileInput">
                  <Button variant="contained" color="primary" component="span">
                    Upload MP3 File
                  </Button>
                </label>
                {campaign.audioFileUrl && 
                  <Box mt={2}>
                    <Typography variant="body1">
                      Selected File: {campaign.audioFileUrl.name}
                    </Typography>
                    <audio
                      controls
                      src={URL.createObjectURL(campaign.audioFileUrl)}
                    />
                  </Box>
                }
              </Box>
            )}
            {messageType === "past" && (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setIsModalOpen(true)}
                >
                  Select Past Recording
                </Button>
                { campaign.past_audioFileUrl &&
                  renderAudioControl()
                }
              </Box>
            )}

            {messageType === "TTS" && (
              <FormControl fullWidth>
                <InputLabel id="ttsVoice-select">Voice Type</InputLabel>
                <Select
                  labelId="ttsVoice-select"
                  id="ttsVoice"
                  placeholder="Voice to use"
                  value={campaign.ttsVoice}
                  onChange={(e) =>
                    handleCampaignChange("ttsVoice", e.target.value)
                  }
                >
                  <MenuItem value={""}>Select a Voice</MenuItem>
                  {["uk-female", "us-female", "uk-male", "us-male"].map((v) => {
                    return (
                      <MenuItem key={v} value={v}>
                        {v}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}

            <FormControl fullWidth>
              <InputLabel id="ttsVoice-select">Send Time</InputLabel>
              <Select
                labelId="sendtIME-select"
                id="sendtime"
                placeholder="Sending Time"
                value={sendTime}
                onChange={(e) => setSendTime(e.target.value)}
              >
                <MenuItem value={"NOW"}>Send Now</MenuItem>
                <MenuItem value={"schedule"}>Send Later</MenuItem>
              </Select>
            </FormControl>

            {sendTime === "schedule" && (
              <TextField
                hiddenLabel
                id="filled-hidden-label-normal"
                defaultValue="Normal"
                variant="outlined"
                type="datetime-local"
                fullWidth
                value={campaign.startTime}
                onChange={(e) =>
                  setCampaign((prev) => ({
                    ...prev,
                    startTime: e.target.value,
                  }))
                }
              />
            )}
          </div>

          <ColorButton
            variant="contained"
            disable={isSending}
            onClick={sendTTS}
            fullWidth
          >
            {isSending ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : (
              "Send Voice SMS"
            )}
          </ColorButton>
        </Container>
      </div>
      <div className="w-full md:w-[30%] lg:w-[25%] shadow-lg px-[10px] py-[20px] h-[500px] overflow-y-auto">
        <Container>
          <h2>Groups</h2>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="flex flex-col gap-2">
              <Box>
                {allGroups.map((group, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={selectedGroups.includes(group.id)}
                        onChange={() => handleGroupToggle(group.id)}
                        name={group.group_name}
                      />
                    }
                    label={`${group.group_name} (${group.contacts_count})`}
                  />
                ))}
              </Box>
            </div>
          )}
        </Container>
      </div>
      <PastRecordingsModal
        open={isModalOpen}
        onClose={closeModal}
        recordings={recordings}
        onSelectRecording={handleRecordingSelect}
      />
      <RequestIDModal
        open={isRequestIDModalOpen}
        onClose={() => setIsRequestIDModalOpen(false)}
        // onSubmit={handleRequestID}
      />
    </section>
  );
};

export default TextToSpeech;
