import React from 'react'

const InfoCard = ({icon,title, subtitle, button}) => {
  return (
    <article className='w-full md:w-auto shadow-lg py-[40px] px-[20px] flex flex-col gap-3 items-center'>
      <div className='text-[#10439F] bg-[#b6e1ff] p-2 rounded-full'>
        {icon}
      </div>
      <p className='text-sm text-gray-400 font-semibold'>{title}</p>
      {button?(button):(<h5 className='font-bold'>{subtitle}</h5>)}
    </article>
  )
}

export default InfoCard