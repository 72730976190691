import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import ReactPaginate from "react-paginate";

const OwnNumber = () => {
  const [ownNumbers, setOwnNumbers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.admin.getOwnNumbers()
      .then(res => {
        setOwnNumbers(res.data['Own-Number']);
      })
      .catch(err => {
        console.error("Error fetching sender IDs:", err);
        toast.error("Failed to load sender IDs.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const pageCount = Math.ceil(ownNumbers.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = ownNumbers.slice(indexOfFirstItem, indexOfLastItem);

  const handleItems = (e) => {
    setItemsPerPage(e.target.value)
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>All Own Numbers</Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ margin: '20px 0' }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S/N</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Own Number</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems.map((sender, idx) => (
                  <TableRow key={sender.id}>
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>{sender.user_id}</TableCell>
                    <TableCell>{sender?.username || 'N/A'}</TableCell>
                    <TableCell>{sender.phone_number}</TableCell>
                    <TableCell>{sender.status}</TableCell>
                    <TableCell>{new Date(sender.created_at).toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <select name="items" value={itemsPerPage} id="itemsperpage" onChange={handleItems} className="m-4 border border-[#1977CC]">
              <option value={20}>20</option>
              <option value={40}>40</option>
              <option value={60}>60</option>
            </select>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"flex justify-center gap-2 my-12"}
              previousLinkClassName={
                "rounded-full border px-4 py-2 mr-2 bg-red-500 text-white"
              }
              nextLinkClassName={
                "rounded-full border px-4 py-2 ml-2 bg-asparagus-500 text-[#1977CC]"
              }
              breakClassName={"text-blue-500 cursor-pointer"}
              activeClassName={
                "bg-blue-500 text-white border rounded-full p-2"
              }
            />
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default OwnNumber;
