import React from 'react'
import Void from '../assets/Void.svg'
import { useNavigate } from 'react-router-dom'

const EmptyState = ({to, buttonMessage, stateMessage}) => {
  const navigate = useNavigate()
  return (
    <div className='flex flex-col gap-3 justify-center'>
      <figure>
        <img src={Void} alt="Empty" className='w-[200px]'/>
      </figure>
      <article>
        <p className='text-center'>{stateMessage}</p>
        <button className='flex justify-center bg-[#10439F] shadow-lg py-2 px-5 text-white rounded-lg text-center w-full hover:bg-[#10438F]' onClick={()=>navigate(to)}>{buttonMessage}</button>
      </article>
    </div>
  )
}

export default EmptyState