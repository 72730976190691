import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../services/api";

const SetEspeeApiKey = () => {
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const handleInputChange = (e) => {
    setApiKey(e.target.value);
  };

  useEffect(() => {
    setIsFetching(true)
    api.admin.getESPEEAPIKEY()
      .then((res) => {
        setApiKey(res.data.espeeApiKey[0].value);
      })
      .catch((err) => {
        toast.error("Failed to get API key.");
        console.error("Error getting API key:", err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("api_key", apiKey);

    api.admin.setESPEEAPIKEY(formData)
      .then((res) => {
        toast.success("ESPEE API key updated successfully!");
        setApiKey("");
      })
      .catch((err) => {
        toast.error("Failed to update API key.");
        console.error("Error updating API key:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: 400,
        margin: "0 auto",
        padding: 2,
        boxShadow: 3,
        borderRadius: 1,
        backgroundColor: "white",
      }}
    >
      <Typography variant="h5" component="h2" gutterBottom>
        Set ESPEE API Key
      </Typography>
      {isFetching ? <CircularProgress /> : <TextField
        label="API Key"
        variant="outlined"
        name="api_key"
        value={apiKey}
        onChange={handleInputChange}
        fullWidth
        required
        sx={{ marginBottom: 2 }}
      />}
      <Button type="submit" variant="contained" color="primary" disabled={loading}>
        {loading ? <CircularProgress size={24} /> : "Submit"}
      </Button>
    </Box>
  );
};

export default SetEspeeApiKey