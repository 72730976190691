import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import api from "../../../services/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";

const CampaignHistory = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setIsFetching(true);
    api.admin
      .getCampaignHistory()
      .then((res) => {
        setCampaigns(res.data.campaigns);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to fetch campaign history.");
      })
      .finally(() => setIsFetching(false));
  }, []);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const pageCount = Math.ceil(campaigns.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = campaigns.slice(indexOfFirstItem, indexOfLastItem);

  const handleItems = (e) => {
    setItemsPerPage(e.target.value);
  };
  return (
    <div>
      <div className="my-4">
        <Typography variant="h4">Campaign History</Typography>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S/N</TableCell>
              <TableCell>User ID</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Contacts</TableCell>
              <TableCell>Failed Contacts</TableCell>
              <TableCell>No Of Failed Contacts</TableCell>
              <TableCell>No Of Sent Contacts</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Message</TableCell>
              {!isMobile && (
                <>
                  <TableCell>Created At</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              currentItems.map((campaign, idx) => (
                <TableRow key={campaign.id}>
                  <TableCell className="text-wrap w-[20px]">
                    {idx + 1}
                  </TableCell>
                  <TableCell className="text-wrap w-[20px]">
                    {campaign.user_id}
                  </TableCell>
                  <TableCell className="text-wrap w-[20px]">
                    {campaign?.username || "N/A"}
                  </TableCell>
                  <TableCell
                    sx={{
                      wordBreak: "break-word",
                      minWidth: "300px",
                    }}
                  >
                    <span>{campaign.contacts}</span>
                  </TableCell>
                  <TableCell
                    sx={{
                      wordBreak: "break-word",
                      minWidth: "300px",
                    }}
                  >
                    <span>{campaign.failed_contacts}</span>
                  </TableCell>
                  <TableCell className="text-wrap w-[10px]">
                    {campaign.no_of_failed_contacts}
                  </TableCell>
                  <TableCell className="text-wrap w-[10px]">
                    {campaign.no_of_sent_contacts}
                  </TableCell>
                  <TableCell className="text-wrap w-[10px]">
                    {campaign.status}
                  </TableCell>
                  <TableCell
                    className="text-wrap w-[30%]"
                    sx={{
                      wordBreak: "break-word",
                      minWidth: "300px",
                    }}
                  >
                    {campaign.msg}
                  </TableCell>
                  {!isMobile && (
                    <>
                      <TableCell className="text-wrap w-[90px]">
                        {new Date(campaign.created_at).toLocaleString()}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <select
          name="items"
          value={itemsPerPage}
          id="itemsperpage"
          onChange={handleItems}
          className="m-4 border border-[#1977CC]"
        >
          <option value={20}>20</option>
          <option value={40}>40</option>
          <option value={60}>60</option>
        </select>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"flex justify-center gap-2 my-12"}
          previousLinkClassName={
            "rounded-full border px-4 py-2 mr-2 bg-red-500 text-white"
          }
          nextLinkClassName={
            "rounded-full border px-4 py-2 ml-2 bg-asparagus-500 text-[#1977CC]"
          }
          breakClassName={"text-blue-500 cursor-pointer"}
          activeClassName={"bg-blue-500 text-white border rounded-full p-2"}
        />
      </TableContainer>
    </div>
  );
};

export default CampaignHistory;
