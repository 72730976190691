import { Container, Paper, Box, CircularProgress } from "@mui/material";
// import { useUser } from "../../Context/UserProvider";
import { useEffect, useState } from "react";
import api from "../../services/api";

const Pricing = () => {
  const [pricing, setPricing] = useState("");
  const [isFetching, setFetching] = useState(false);

  // const { prices } = useUser();

  useEffect(() => {
    setFetching(true);
    api.admin
      .getAboutUs()
      .then((res) => {
        const aboutText = res.data["About-Us"][0].value;
        setPricing(aboutText);
      })
      .catch((err) => {
        console.error("Error fetching About Us content:", err);
      })
      .finally(() => setFetching(false));
  }, []);

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 2, marginTop: 4 }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ padding: "20px" }}
        >
          <h2 className="text-2xl mb-4 font-extrabold">Pricing</h2>
          {/* <div>
            <ol className="list-[capital-alpha] flex flex-col gap-4 text-justify">
              <li>
                <span className="font-bold">
                  PRICING AND CHARGES FOR BULKSMS:
                </span>
                <p className="pb-3">
                  {" "}
                  You are charged{" "}
                  <span className="font-bold text-[#1977CC]">
                    {prices.smsUnitPrice}
                  </span>{" "}
                  espees per sms page sent. The espees is deducted from you
                  account as you send. Each sms page is{" "}
                  <span className="font-bold">
                    156 characters including spaces
                  </span>
                  .
                </p>
                <span className="font-bold">SENDER IDs FOR BULKSMS:</span>
                <p>
                  Sender ID request is free and must not be more than{" "}
                  <span className="font-bold">11 characters</span>, space
                  inclusive. You can request as many sender IDs as you require
                  for your campaigns. It takes between 10mins to 24hrs for
                  approval of Sender IDs.
                </p>
              </li>
              <li>
                <span className="font-bold">
                  PRICING AND CHARGES FOR ROBOCALLS:
                </span>
                <p>
                  {" "}
                  Robocall charges depend on the sender id you choose when
                  sending your calls.{" "}
                  <span className="font-bold">
                    1unit of call = 30seconds.
                  </span>{" "}
                  This means if your voice message exceeds 30seconds, the system
                  Charges accordingly. You are only charged for answered calls.
                  <ul className="list-disc flex flex-col gap-3 ml-[30px] mt-4">
                    <li>
                      Use Default Sender Id...{" "}
                      <span className="font-bold text-[#1977CC]">
                        {prices.voiceUnitPrice}
                      </span>{" "}
                      espees per unit.
                    </li>

                    <li>
                      Use Virtual Number ....
                      <span className="font-bold text-[#1977CC]">
                        {prices.virtualUnitPrice}
                      </span>{" "}
                      espees per unit.
                    </li>

                    <li>
                      Use Own Number ....
                      <span className="font-bold text-[#1977CC]">
                        {prices.ownUnitPrice}
                      </span>{" "}
                      espees per unit.
                    </li>
                  </ul>
                </p>
              </li>
            </ol>
          </div> */}
          {isFetching ? (
            <div className="flex justify-center items-center h-full w-full">
              <CircularProgress />
            </div>
          ) : (
            <div
              className="space-y-6 max-w-4xl mx-auto text-lg text-gray-700 leading-relaxed"
              dangerouslySetInnerHTML={{ __html: pricing }}
            />
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default Pricing;
