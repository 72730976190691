import React, { useState } from 'react';
import {
 Container,
 TextField,
 Button,
 Typography,
 Box,
 Grid,
 CircularProgress,
} from '@mui/material';
import { auth } from '../../services/api/auth'
import { toast } from 'react-toastify'
import { useUser } from '../../Context/UserProvider';
import service from '../../services';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
 const { setUser } = useUser()
 const navigate = useNavigate()
 const [formData, setFormData] = useState({
  email: '',
  password: '',
 });
 const[loading, setLoading] = useState(false)

 const [errors, setErrors] = useState({});

 const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
   ...prevData,
   [name]: value,
  }));
 };

 const validateForm = () => {
  let formErrors = {};

  if (!formData.email) {
   formErrors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
   formErrors.email = 'Email address is invalid';
  }

  if (!formData.password) {
   formErrors.password = 'Password is required';
  }

  setErrors(formErrors);

  return Object.keys(formErrors).length === 0;
 };

 const handleSubmit = (e) => {
  setLoading(true)
  e.preventDefault();
  const formDetails = new FormData()
  formDetails.append('email', formData.email)
  formDetails.append('password', formData.password)

  if (validateForm()) {
   auth.adminLogin(formDetails).then((res) => {
    console.log(res)
    if (res.status === 200) {
     toast.success('Logged in Successfully')
     setUser(res.data?.user)
     service.setTokensLocalStorage(res.data?.authorization?.token)
     navigate('/dashboard/admin')
    }
   }).catch(res => console.log(res)).finally(()=>setLoading(false))

  }
 };

 return (
  <Container maxWidth="sm">
   <Box mt={4}>
    <Typography variant="h4" align="center" gutterBottom>
     Login
    </Typography>
    <form onSubmit={handleSubmit}>
     <Grid container spacing={2}>
      <Grid item xs={12}>
       <TextField
        fullWidth
        label="Email"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChange}
        error={!!errors.email}
        helperText={errors.email}
       />
      </Grid>
      <Grid item xs={12}>
       <TextField
        fullWidth
        label="Password"
        name="password"
        type="password"
        value={formData.password}
        onChange={handleChange}
        error={!!errors.password}
        helperText={errors.password}
       />
      </Grid>
      <Grid item xs={12}>
       <Button
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        disabled={loading===true}
       >
        {loading ? <CircularProgress /> :'Login'}
       </Button>
      </Grid>
     </Grid>
    </form>
   </Box>
  </Container>
 );
};

export default LoginPage;
