import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Modal, TextField, Typography } from "@mui/material";

const RejectionModal = ({ open, onClose, onSubmit, loading }) => {
  const [reason, setReason] = useState("");

  const handleSubmit = () => {
    onSubmit(reason);
  };

  useEffect(() => {
    if (!open) {
      setReason("");
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="rejection-modal-title"
      aria-describedby="rejection-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}>
        <Typography id="rejection-modal-title" variant="h6" component="h2">
          Reason for Rejection
        </Typography>
        <TextField
          id="rejection-modal-description"
          label="Reason"
          multiline
          rows={4}
          fullWidth
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </Box>
    </Modal>
  );
};

export default RejectionModal;
