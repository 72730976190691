import React, { useState } from 'react';
import {
 Container,
 Avatar,
 // Button,
 Typography,
 Paper,
 IconButton,
 Box
} from '@mui/material';
// import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { useUser } from '../../Context/UserProvider';
// import api from '../../services/api';


const ProfilePage = () => {
 const { user } = useUser()
 
// eslint-disable-next-line
 const [profile, setProfile] = useState({
  profilePicture: user?.avatar,
  username: user?.username,
  email: user.email,
  name: user.name,
  phoneNumber: user?.phone_number,
 });

 return (
  <Container maxWidth="sm">
   <Paper elevation={3} sx={{ padding: 2, marginTop: 4 }}>
    <Box display="flex" flexDirection="column" alignItems="center">
     <label htmlFor="upload-photo">
      <IconButton color="primary" component="span">
       <Avatar
        sx={{ width: 80, height: 80 }}
        src={profile.profilePicture}
       />
      </IconButton>
     </label>
     <Typography variant="h6">{profile.username}</Typography>
     <Typography variant="body1" sx={{ marginTop: 2 }}>
      Email: {profile.email}
     </Typography>
     <Typography variant="body1">Name: {profile.name}</Typography>
     <Typography variant="body1">
      Phone Number: {profile.phoneNumber}
     </Typography>
    </Box>
   </Paper>
  </Container>
 );
};

export default ProfilePage;
