import React from 'react';
import { Modal, Box, Button, Typography, CircularProgress } from '@mui/material';

const ConfirmSendingModal = ({ open, handleClose, content, confirm, sending, ...rest }) => {
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxHeight: '90vh',
    maxWidth: '500px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto'
  };


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{overflow:'scroll'}}
    >
      <Box sx={modalStyle} className="rounded-lg shadow-lg">
        <Typography id="modal-title" variant="h6" component="h2" className="mb-4">
          Confirm Campaign Submission
        </Typography>
        <ul className="space-y-4">
          <li className="flex justify-between border-t border-b py-2">
            <span>Route</span>
            <span className='bg-blue-500 px-3 py-1 rounded-sm text-white'>DEFAULT</span>
          </li>
          <li className="flex justify-between border-b py-2">
            <span>SENDER ID</span>
            <span className='font-semibold'>{rest?.senderId}</span>
          </li>
          <li className="flex flex-col gap-3 border-b py-2">
            <span>Message</span>
            <span className='font-semibold'>{content?.message}</span>
          </li>
          <li className="flex justify-between border-b py-2">
            <span>Total Contacts</span>
            <span>{rest?.total_contacts}</span>
          </li>
          <li className="flex justify-between border-b py-2">
            <span>Duplicate removed</span>
            <span>{rest?.total_duplicates_removed
            }</span>
          </li>
          <li className="flex justify-between border-b py-2">
            <span>Total ESPEES</span>
            <span className='text-red-600'>{(rest?.total_charges).toFixed(5)
            } espees</span>
          </li>
          <li className="flex justify-between border-b py-2">
            <span>SMS TYPE</span>
            <span className='bg-blue-500 px-3 py-1 rounded-sm text-white'>{rest?.sms_type}</span>
          </li>
          <li className="flex justify-between">
            <span>Campaign Start</span>
            <spanc className='font-semibold'>{content?.time === '' ? 'Sending Now' : content?.time}</spanc>
          </li>
        </ul>
        <div className="flex justify-start mt-6 space-x-4">
          <Button variant="contained" color="primary" onClick={confirm} disabled={sending}>
            {sending ? <CircularProgress /> : 'Yes, Proceed'}
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
           No, Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmSendingModal;
