import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../services/api";

const SetVoiceUnitPrice = () => {
  const [price, setPrice] = useState("");
  const [virtualprice, setVirtualPrice] = useState("");
  const [ownprice, setOwnPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true)
    api.admin.getVoiceUNITPRICE()
      .then((res) => {
        setPrice(res.data['SMS-UNIT-PRICE'][0].value);
      })
      .catch((err) => {
        toast.error("Failed to get Unit Price.");
        console.error("Error getting Unit Price:", err);
      })
      .finally(() => {
        setIsFetching(false);
      });

    api.admin.getVirtualUNITPRICE()
      .then((res) => {
        setVirtualPrice(res.data['VIRTUAL-UNIT-PRICE'][0].value || 0.00);
      })
      .catch((err) => {
        toast.error("Failed to get Unit Price.");
        console.error("Error getting Unit Price:", err);
      })
      .finally(() => {
        setIsFetching(false);
      });
    api.admin.getOwnNumberUNITPRICE()
      .then((res) => {
        setOwnPrice(res.data['OWN-UNIT-PRICE'][0].value || 0.00);
      })
      .catch((err) => {
        toast.error("Failed to get Unit Price.");
        console.error("Error getting Unit Price:", err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [])

  const handleInputChange = (e) => {
    setPrice(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setIsFetching(true)

    const formData = new FormData();
    formData.append("price", price);

    api.admin.setVoiceUNITPRICE(formData)
      .then((res) => {
        toast.success("Unit Price updated successfully!");
        setPrice("");
      })
      .catch((err) => {
        toast.error("Failed to update Unit Price.");
        console.error("Error updating Unit Price:", err);
      })
      .finally(() => {
        setLoading(false);
        setIsFetching(false);
      });
  };

  const handleVirtual = (e) => {
    e.preventDefault();
    setLoading(true);
    setIsFetching(true)

    const formData = new FormData();
    formData.append("price", virtualprice);

    api.admin.setVirtualUNITPRICE(formData)
      .then((res) => {
        toast.success("Virtual Unit Price updated successfully!");
        setVirtualPrice("");
      })
      .catch((err) => {
        toast.error("Failed to update Unit Price.");
        console.error("Error updating Unit Price:", err);
      })
      .finally(() => {
        setLoading(false);
        setIsFetching(false);
      });
  };

  const handleOwn = (e) => {
    e.preventDefault();
    setLoading(true);
    setIsFetching(true)

    const formData = new FormData();
    formData.append("price", ownprice);

    api.admin.setOwnNumberUNITPRICE(formData)
      .then((res) => {
        toast.success("Own Number Unit Price updated successfully!");
        setOwnPrice("");
      })
      .catch((err) => {
        toast.error("Failed to update Unit Price.");
        console.error("Error updating Unit Price:", err);
      })
      .finally(() => {
        setLoading(false);
        setIsFetching(false);
      });
  };

  return (
    <div>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: 400,
          margin: "0 auto",
          padding: 2,
          boxShadow: 3,
          borderRadius: 1,
          backgroundColor: "white",
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          Set Voice Unit Price
        </Typography>
        {isFetching ? <CircularProgress /> : <TextField
          label="Unit Price"
          variant="outlined"
          name="price"
          value={price}
          onChange={handleInputChange}
          fullWidth
          required
          sx={{ marginBottom: 2 }}
        />}
        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </Box>
      <div className="flex flex-col lg:flex-row gap-5 my-3">
        <Box
          fullWidth
          component="form"
          onSubmit={handleVirtual}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "0 auto",
            padding: 2,
            boxShadow: 3,
            borderRadius: 1,
            backgroundColor: "white",
            maxWidth: 400,
            width: '100%'
          }}
        >
          <Typography variant="h5" sx={{ textAlign: 'center' }} component="h2" gutterBottom>
            Set Voice Unit Price (Virtual Number)
          </Typography>
          {isFetching ? <CircularProgress /> : <TextField
            label="Virtual Unit Price"
            variant="outlined"
            name="virtualprice"
            value={virtualprice}
            onChange={(e)=>setVirtualPrice(e.target.value)}
            fullWidth
            required
            sx={{ marginBottom: 2 }}
          />}
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
        <Box
          component="form"
          onSubmit={handleOwn}
          fullWidth
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "0 auto",
            padding: 2,
            boxShadow: 3,
            borderRadius: 1,
            backgroundColor: "white",
            maxWidth: 400,
            width: '100%'
          }}
        >
          <Typography variant="h5" sx={{ textAlign: 'center' }} component="h2" gutterBottom>
            Set Voice Unit Price (Own Number)
          </Typography>
          {isFetching ? <CircularProgress /> : <TextField
            label="Unit Price"
            variant="outlined"
            name="price"
            value={ownprice}
            onChange={(e)=>setOwnPrice(e.target.value)}
            fullWidth
            required
            sx={{ marginBottom: 2 }}
          />}
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default SetVoiceUnitPrice