import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../Components/Footer'
import OtherNavbar from '../Components/OtherNavbar'

const LoginLayout = () => {
  return (
    <div className=''>
      <OtherNavbar />
      <div className='my-4 min-h-screen'>
        <Outlet/>
      </div>
      <Footer />
    </div>
  )
}

export default LoginLayout