import React from 'react';
import { Box, Container, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const AboutUs = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box>
        <Typography variant="h4" gutterBottom>
          About Us
        </Typography>
        <Typography variant="body1" paragraph>
          Text & Voice is an online Bulk SMS platform for sending text messages from any internet connected device.
        </Typography>
        <Typography variant="body1" paragraph>
          Text & Voice is a company which caters for the provision of information technology services including Website design, Web application development, customised software development, and more.
        </Typography>
        <Typography variant="body1" paragraph>
          We currently deliver SMS to four major GSM networks in Nigeria including, Glo, MTN, Airtel, and 9Mobile, and most of the major GSM networks internationally.
        </Typography>
        <Typography variant="body1" paragraph>
          Our business is information technology and that means that we use technology to aggregate, manipulate and disseminate information in a way that makes it useful to the end users.
        </Typography>
        <Typography variant="body1" paragraph>
          Efficiency is our watchword, hence the need to provide adequate room for research and development. Hence we ensure that our customers get the best user experience from using our services, and provide support accordingly.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Features of our SMS platform include:
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Auto phone number extraction from text" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Delivery reports for each SMS sent" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Instant speedy dispatch of thousands of SMS at a single click" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Scheduled SMS for times when you may be away from the Internet" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Easy phone book/contacts management" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Import/insert contacts and phone numbers from text files in any format" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Manage contacts using contact lists of number groups" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Dynamic/customised sender name of up to 11 characters, or 14 numbers" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Long/multi-page SMS with up to six (6) pages in one message" />
          </ListItem>
        </List>
        <Typography variant="body1" paragraph>
          Constant improvement is very important to us and that’s why we are here for you.
        </Typography>
      </Box>
    </Container>
  );
}

export default AboutUs;
