import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const Announcement = () => {
  const [message, setMessage] = useState("");
  const [announcements, setAnnouncements] = useState([])
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    api.admin.getAnnouncements().then(res => { setAnnouncements(res.data?.Announcement) }).catch(console.error)
  }, [])

  const handleSubmit = (e, action, id) => {
    e.preventDefault();
    setLoading(true);
    if (action === 'create' && message === "") {
      toast.error("Please enter a message");
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("announcement", message);
    formData.append("action", action);
    if (id) {
      formData.append('id', id)
    }
    api.admin
      .makeAnnouncement(formData)
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload()
        setMessage("");
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  const handleEdit = (announcement) => {
    setMessage(announcement.announcement);
    setIsEditing(true);
    setEditId(announcement.id);
  };

  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const pageCount = Math.ceil(announcements.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = announcements.slice(indexOfFirstItem, indexOfLastItem);

  const handleItems = (e) => {
    setItemsPerPage(e.target.value)
  }


  return (
    <section>
      <Box
        component="form"
        onSubmit={(e) => handleSubmit(e, isEditing ? 'edit' : 'create', editId)}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "90%",
          maxWidth: 500,
          margin: "0 auto",
          padding: 2,
          boxShadow: 3,
          borderRadius: 1,
          backgroundColor: "white",
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          {isEditing ? "Edit Announcement" : "Type Announcement"}
        </Typography>
        <TextField
          label="Announcement"
          variant="outlined"
          name="announcement"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          fullWidth
          required
          multiline
          rows={10}
          sx={{ marginBottom: 2 }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : isEditing ? "Edit" : "Submit"}
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ margin: "10px 0" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S/N</TableCell>
              <TableCell>Announcement</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItems.map((announcement, idx) => (
              <TableRow key={announcement.id}>
                <TableCell>{idx + 1}</TableCell>
                <TableCell>{announcement?.announcement}</TableCell>
                <TableCell>{announcement.is_active === 1 ? 'Active' : 'Inactive'}</TableCell>
                <TableCell>
                  <div className='flex gap-4'>
                    {announcement.is_active !== 1 && <Tooltip title="Activate" arrow >
                      <DoneOutlineIcon onClick={(e) => handleSubmit(e, 'activate', announcement.id)} className='cursor-pointer' />
                    </Tooltip>}
                    {announcement.is_active === 1 && <Tooltip title="Deactivate" arrow >
                      <ClearIcon onClick={(e) => handleSubmit(e, 'deactivate', announcement.id)} className='cursor-pointer' />
                    </Tooltip>}
                    <Tooltip title="Edit" arrow>
                      <EditIcon onClick={() => handleEdit(announcement)} className='cursor-pointer' />
                    </Tooltip>
                    <Tooltip title="Delete" arrow >
                      <DeleteForeverIcon onClick={(e) => handleSubmit(e, 'delete', announcement.id)} className='cursor-pointer' />
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <select name="items" value={itemsPerPage} id="itemsperpage" onChange={handleItems} className="m-4 border border-[#1977CC]">
          <option value={20}>20</option>
          <option value={40}>40</option>
          <option value={60}>60</option>
        </select>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"flex justify-center gap-2 my-12"}
          previousLinkClassName={
            "rounded-full border px-4 py-2 mr-2 bg-red-500 text-white"
          }
          nextLinkClassName={
            "rounded-full border px-4 py-2 ml-2 bg-asparagus-500 text-[#1977CC]"
          }
          breakClassName={"text-blue-500 cursor-pointer"}
          activeClassName={
            "bg-blue-500 text-white border rounded-full p-2"
          }
        />
      </TableContainer>
    </section>
  );
};

export default Announcement;
