import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import ReactPaginate from "react-paginate";


const AssignESPEES = () => {
  const [transactions, setTransactions] = useState([]);
  const [virtual, setVirtual] = useState({
    number: '',
    userId: '',
    payment_ref:''
  });
  const [loading, setLoading] = useState(true);
  const [assigning, setAssigning] = useState(false);

  const handleVirtual = (e) => {
    const { name, value } = e.target
    setVirtual((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  useEffect(() => {
    api.admin.getAllTransations()
      .then(res => {
        setTransactions(res.data?.Transactions);
      })
      .catch(err => {
        console.error("Error fetching transactions:", err);
        toast.error("Failed to load transactions");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const Assign = () => {
    setAssigning(true)
    const formData = new FormData()
    formData.append('espees', virtual.number)
    formData.append('payment_ref', virtual.payment_ref)

    if (!virtual.number) {
      toast.error("Kindly input ESPEES amount")
      setAssigning(false);
      return
    }
    if (!virtual.userId) {
      toast.error("Kindly input User's ID")
      setAssigning(false);
      return
    }
    if (!virtual.payment_ref) {
      toast.error("Kindly input Payment Ref")
      setAssigning(false);
      return
    }
    api.admin.assignESP(virtual.userId, formData)
      .then(res => {
        setVirtual({
          number: '',
          userId: '',
          payment_ref:''
        })
        window.location.reload()
        toast.success(res.data.message);
      })
      .catch(err => {
        console.error("Error approving virtual number:", err);
        toast.error(err.response.data?.message);
      })
      .finally(() => {
        setAssigning(false);
      });
  }

  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const pageCount = Math.ceil(transactions.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = transactions.slice(indexOfFirstItem, indexOfLastItem);

  const handleItems = (e) => {
    setItemsPerPage(e.target.value)
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>Assign failed ESPEES transaction</Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ margin: '20px 0' }}>
          <div className='mb-6'>
            <Typography variant="p" sx={{ fontSize: '15px' }}>
              Input ESPEES Amount and User's ID
            </Typography>
            <div className='flex flex-col lg:flex-row gap-3 w-full mt-4'>
              <TextField
                label="ESPEES Amount"
                variant="outlined"
                name="number"
                value={virtual.number}
                onChange={handleVirtual}
                required
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="Payment Reference"
                variant="outlined"
                name="payment_ref"
                value={virtual.payment_ref}
                onChange={handleVirtual}
                required
                sx={{ marginBottom: 2, }}
              />
              <TextField
                label="User ID"
                variant="outlined"
                name="userId"
                value={virtual.userId}
                onChange={handleVirtual}
                required
                sx={{ marginBottom: 2, }}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={Assign}
            >
              {assigning ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Approve'}
            </Button>
          </div>
          <TableContainer component={Paper} sx={{ margin: "10px 0" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S/N</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>Payment Reference</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Transaction Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems.map((transaction, idx) => (
                  <TableRow key={transaction.id}>
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>{transaction?.username || 'N/A'}</TableCell>
                    <TableCell>{transaction?.user_id}</TableCell>
                    <TableCell>{transaction.payment_ref}</TableCell>
                    <TableCell>{transaction.price}</TableCell>
                    <TableCell>{transaction.status_details}</TableCell>
                    <TableCell>{transaction.transaction_date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <select name="items" value={itemsPerPage} id="itemsperpage" onChange={handleItems} className="m-4 border border-[#1977CC]">
              <option value={20}>20</option>
              <option value={40}>40</option>
              <option value={60}>60</option>
            </select>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"flex justify-center gap-2 my-12"}
              previousLinkClassName={
                "rounded-full border px-4 py-2 mr-2 bg-red-500 text-white"
              }
              nextLinkClassName={
                "rounded-full border px-4 py-2 ml-2 bg-asparagus-500 text-[#1977CC]"
              }
              breakClassName={"text-blue-500 cursor-pointer"}
              activeClassName={
                "bg-blue-500 text-white border rounded-full p-2"
              }
            />
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default AssignESPEES;
