import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import CloseIcon from '@mui/icons-material/Close';
import api from "../services/api";

const Announcement = () => {
  const [upperAnnouncements, setUpperAnnouncements] = useState([]);
  const [lowerAnnouncements, setLowerAnnouncements] = useState([]);
  const [showUpper, setShowUpper] = useState(true);
  const [showLower, setShowLower] = useState(true);

  useEffect(() => {
    api.getAnnouncement()
      .then((res) => {
        const activeAnnouncements = res.data.Announcement.filter(ann => ann.is_active === 1);
        const midIndex = Math.ceil(activeAnnouncements.length / 2);
        setUpperAnnouncements(activeAnnouncements.slice(0, midIndex));
        setLowerAnnouncements(activeAnnouncements.slice(midIndex));
      })
      .catch(console.error);
  }, []);

  const handleClose = (type) => {
    if (type === 'upper') {
      setShowUpper(false);
    } else if (type === 'lower') {
      setShowLower(false);
    }
  };

  const settings = {
    infinite: true,
    speed: 20000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 500,
    cssEase: "linear",
    pauseOnHover: false,
    arrows: false,
    centerMode: true
  };

  const loopAnnouncements = (announcements) => {
    return announcements.length > 1 ? announcements : Array(3).fill(announcements[0]);
  };

  const repeatedUpperAnnouncements = loopAnnouncements(upperAnnouncements);
  const repeatedLowerAnnouncements = loopAnnouncements(lowerAnnouncements);

  return (
    <>
      {showUpper && upperAnnouncements.length > 0 && (
        <div className="bg-blue-500 relative text-white italic py-3">
          <button type="button" onClick={() => handleClose('upper')} className="block cursor-pointer absolute right-4 bg-red-600 rounded-full z-[1000]">
            <CloseIcon sx={{ fontSize: '20px', zIndex: '1000' }} />
          </button>
          <Slider {...settings}>
            {repeatedUpperAnnouncements.map((ann, index) => (
              <div key={index} className="px-4 flex justify-between items-center">
                <p>{ann.announcement}</p>
              </div>
            ))}
          </Slider>
        </div>
      )}
      {showLower && lowerAnnouncements.length > 0 && (
        <div className="bg-blue-300 relative text-white italic py-3">
          <button type="button" onClick={() => handleClose('lower')} className="block cursor-pointer absolute right-4 bg-red-600 rounded-full z-[1000]">
            <CloseIcon sx={{ fontSize: '20px', zIndex: '1000' }} />
          </button>
          <Slider {...settings}>
            {repeatedLowerAnnouncements.map((ann, index) => (
              <div key={index} className="px-4 flex justify-between items-center">
                <p>{ann.announcement}</p>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
};

export default Announcement;
