import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../services/api";

const SetContactPhone = () => {
  const [phone, setPhone] = useState("");
  const [adminPhone, setAdminPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true)
    api.admin.getContactPhone()
      .then((res) => {
        setPhone(res.data['Contact-phone'][0].value || "N/A");
      })
      .catch((err) => {
        toast.error("Failed to get Contact Phone.");
        console.error("Error getting Contact Phone:", err);
      })
      .finally(() => {
        setIsFetching(false);
      });
    api.admin.getAdminPhone()
      .then((res) => {
        setAdminPhone(res.data['Admin-Number'][0].value || "N/A");
      })
      .catch((err) => {
        toast.error("Failed to get Contact Phone.");
        console.error("Error getting Contact Phone:", err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [])

  const handleInputChange = (e) => {
    setPhone(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("phone", phone);

    api.admin.setContactPhone(formData)
      .then((res) => {
        toast.success("Second number updated successfully!");
        setPhone("");
      })
      .catch((err) => {
        toast.error("Failed to set second number.");
        console.error("Error updating second number:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const handleAdminSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("phone_number", adminPhone);

    api.admin.setAdminPhone(formData)
      .then((res) => {
        toast.success("second number updated successfully!");
        setPhone("");
      })
      .catch((err) => {
        toast.error("Failed to set second number.");
        console.error("Error updating second number:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col gap-8">
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: 400,
          margin: "0 auto",
          padding: 2,
          boxShadow: 3,
          borderRadius: 1,
          backgroundColor: "white",
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          Set Second Number
        </Typography>
        {isFetching ? <CircularProgress /> : <TextField
          label="Contact Phone"
          variant="outlined"
          name="phone"
          value={phone}
          onChange={handleInputChange}
          fullWidth
          required
          sx={{ marginBottom: 2 }}
        />}
        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </Box>

      <Box
        component="form"
        onSubmit={handleAdminSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: 400,
          margin: "0 auto",
          padding: 2,
          boxShadow: 3,
          borderRadius: 1,
          backgroundColor: "white",
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          Set Admin Phone
        </Typography>
        {isFetching ? <CircularProgress /> : <TextField
          label="Admin Phone"
          variant="outlined"
          name="phone"
          value={adminPhone}
          onChange={(e)=>setAdminPhone(e.target.value)}
          fullWidth
          required
          sx={{ marginBottom: 2 }}
        />}
        <Button type="submit" variant="contained" color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </Box>
    </div>
  );
};

export default SetContactPhone