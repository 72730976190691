import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import api from "../../../services/api";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import RejectionModal from "../../../Components/RejectionModal";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const ApproveSenderID = () => {
  const [senderIDs, setSenderIDs] = useState([]);
  const [othersenderIDs, setOtherSenderIDs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [approvingId, setApprovingId] = useState(null);
  const [rejectingId, setRejectingId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState("all");
  const navigate = useNavigate();

  useEffect(() => {
    api.admin
      .getSenderIDs()
      .then((res) => {
        setSenderIDs(res.data.senderids);
        setOtherSenderIDs(res.data.senderids);
      })
      .catch((err) => {
        console.error("Error fetching sender IDs:", err);
        toast.error("Failed to load sender IDs.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleApprove = (id) => {
    setApprovingId(id);
    api.admin
      .approveID(id)
      .then((res) => {
        setSenderIDs(
          senderIDs.map((item) =>
            item.id === id ? { ...item, status: "approved" } : item
          )
        );
        toast.success("Sender ID approved successfully!");
      })
      .catch((err) => {
        console.error("Error approving sender ID:", err);
        toast.error("Failed to approve sender ID.");
      })
      .finally(() => {
        setApprovingId(null);
      });
  };

  const handleReject = (id) => {
    setRejectingId(id);
    setIsModalOpen(true);
  };

  const handleSubmitRejection = (reason) => {
    setModalLoading(true);
    api.admin
      .rejectID(rejectingId, { reason })
      .then((res) => {
        setSenderIDs(
          senderIDs.map((item) =>
            item.id === rejectingId ? { ...item, status: "rejected" } : item
          )
        );
        toast.success("Sender ID rejected successfully!");
        setIsModalOpen(false);
      })
      .catch((err) => {
        console.error("Error rejecting sender ID:", err);
        toast.error("Failed to reject sender ID.");
      })
      .finally(() => {
        setModalLoading(false);
        setRejectingId(null);
      });
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const pageCount = Math.ceil(senderIDs.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = senderIDs.slice(indexOfFirstItem, indexOfLastItem);

  const handleItems = (e) => {
    setItemsPerPage(e.target.value);
  };

  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilter(selectedFilter);

    if (selectedFilter === "all") {
      setSenderIDs(othersenderIDs);
    } else {
      setSenderIDs(
        othersenderIDs.filter((senderid) => senderid.status === selectedFilter)
      );
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        Approve Sender IDs
      </Typography>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div>
          <div className="flex flex-col gap-2 w-full justify-end items-end align-right ml-auto my-6">
            <FilterListIcon onClick={() => setOpenFilter(!openFilter)} />
            {openFilter && (
              <select
                name="filter"
                value={filter}
                id=""
                onChange={handleFilterChange}
                className="bg-transparent border-2 border-blue-300"
              >
                <option value="all">Show All</option>
                <option value="approved">Show Only Approved</option>
                <option value="disapproved">Show Only Disapproved</option>
              </select>
            )}
          </div>
          <div>
            <p className="text-center py-3 font-bold text-[25px]">
              Showing {filter} Sender ID
            </p>
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S/N</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Sender ID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems.map((sender, idx) => (
                  <TableRow
                    key={sender.id}
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(
                        `/dashboard/admin/manage-users/user/${sender.user_id}`
                      )
                    }
                  >
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>
                      <Link
                        to={`/dashboard/admin/manage-users/user/${sender.user_id}`}
                        className="underline"
                      >
                        {sender.user_id}
                      </Link>
                    </TableCell>
                    <TableCell>{sender?.username || "N/A"}</TableCell>
                    <TableCell>{sender.sender_id}</TableCell>
                    <TableCell>{sender.status}</TableCell>
                    <TableCell>
                      {new Date(sender.created_at).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={
                          approvingId === sender.id ||
                          sender.status === "approved"
                        }
                        onClick={() => handleApprove(sender.id)}
                        sx={{ marginRight: 1 }}
                      >
                        {approvingId === sender.id ? (
                          <CircularProgress size={24} />
                        ) : sender.status === "pending" ||
                          sender.status === "disapproved" ? (
                          "Approve"
                        ) : (
                          "Approved"
                        )}
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={
                          rejectingId === sender.id ||
                          sender.status === "disapproved"
                        }
                        onClick={() => handleReject(sender.id)}
                      >
                        {rejectingId === sender.id ? (
                          <CircularProgress size={24} />
                        ) : sender.status === "pending" ||
                          sender.status === "approved" ? (
                          "Reject"
                        ) : (
                          "Rejected"
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <select
              name="items"
              value={itemsPerPage}
              id="itemsperpage"
              onChange={handleItems}
              className="m-4 border border-[#1977CC]"
            >
              <option value={20}>20</option>
              <option value={40}>40</option>
              <option value={60}>60</option>
            </select>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"flex justify-center gap-2 my-12"}
              previousLinkClassName={
                "rounded-full border px-4 py-2 mr-2 bg-red-500 text-white"
              }
              nextLinkClassName={
                "rounded-full border px-4 py-2 ml-2 bg-asparagus-500 text-[#1977CC]"
              }
              breakClassName={"text-blue-500 cursor-pointer"}
              activeClassName={"bg-blue-500 text-white border rounded-full p-2"}
            />
          </TableContainer>
        </div>
      )}
      <RejectionModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSubmitRejection}
        loading={modalLoading}
      />
    </Box>
  );
};

export default ApproveSenderID;
