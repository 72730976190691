import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import api from "../../../services/api";
import format from '../../../assets/contacts_format.csv'
import format1 from '../../../assets/contacts_txt.txt'
import Loader from "../../../Components/Loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const UploadContact = () => {
  const [uploadToNewGroup, setUploadToNewGroup] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [isUploading, setIsUploading] = useState(false)

  const navigate = useNavigate()

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    padding: "10px 0",
    "&:hover": {
      backgroundColor: "#4bb5ff",
      color: "white",
    },
  }));
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const [imports, setImports] = useState({
    file: null,
    group_name: "",
    description: "",
  });

  const handleFileChange = (event) => {
    setImports({
      ...imports,
      file: event.target.files[0],
    });
  };

  const handleChange = (event) => {
    setImports({
      ...imports,
      group_name: event.target.value,
    });
  };

  useEffect(() => {
    api.contacts
      .getGroup()
      .then((res) => {
        setAllGroups(res.data?.groups);
      })
      .catch((res) => console.log(res));
  }, []);

  const uploadContact = () => {
    setIsUploading(true)

    if (!imports.file || !imports.group_name) {
      toast.error("Please provide a file and group name.");
      setIsUploading(false);
      return;
    }

    const formData = new FormData();
    if (!uploadToNewGroup) {
      formData.append("file", imports.file);
      formData.append("group_name", imports.group_name);
      formData.append("description", imports.description);
      api.contacts
        .importToNewGroup(formData)
        .then((res) => {
          toast.success(res.data?.message);
          navigate('/dashboard/phonebook')
        })
        .catch((res) => console.log(res)).finally(() => setIsUploading(false));
    } else {
      formData.append("file", imports.file);
      api.contacts
        .importToOldGroup(imports.group_name, formData)
        .then((res) => {
          toast.success(res.data?.message)
          navigate('/dashboard/phonebook')
        })
        .catch((res) => console.log(res)).finally(() => setIsUploading(false));
    }
  };

  return (
    <section className="w-full md:w-[85%] mx-auto flex justify-center ">
      <div className="w-full lg:w-[45%] shadow-xl p-[15px] flex flex-col gap-4">
        <Typography variant="h5">Upload Contacts</Typography>
        <Typography variant="p" component="p" sx={{ fontSize: 13 }}>
          Upload format (Select a CSV File, Each line should contain Phone
          number)
        </Typography>
        <div className="flex gap-4">
          <a
            href={format}
            download="contacts_format.csv"
            style={{ textDecoration: "none" }}
          >
            <ColorButton
              variant="outlined"
              sx={{ color: "#4bb5ff", padding: '5px' }}
              fullWidth
              endIcon={<LibraryBooksIcon />}
            >
              Download CSV Format
            </ColorButton>
          </a>
          <a
            href={format1}
            download="contacts_format.txt"
            style={{ textDecoration: "none" }}
          >
            <ColorButton
              variant="outlined"
              sx={{ color: "#4bb5ff", padding: '5px' }}
              fullWidth
              endIcon={<LibraryBooksIcon />}
            >
              Download TXT Format
            </ColorButton>
          </a>
        </div>
        <div>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            Select a file
            <VisuallyHiddenInput type="file" onChange={handleFileChange} />
          </Button>
          {imports.file && <Typography>{imports.file.name}</Typography>}
          <article className="flex flex-col gap-4 mb-4 justify-center items-center text-center">
            <Typography variant="p">
              How do you want to upload your contacts
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Create a group</Typography>
              <AntSwitch
                checked={uploadToNewGroup}
                onChange={() => setUploadToNewGroup(!uploadToNewGroup)}
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography>Upload to group</Typography>
            </Stack>
          </article>
          <div className="mb-4">
            {!uploadToNewGroup ? (
              <TextField
                id="group"
                label="Name for new contact group"
                fullWidth
                onChange={handleChange}
              />
            ) : (
              <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                <Select
                  value={imports.group_name}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">
                    <em>Choose group</em>
                  </MenuItem>
                  {allGroups.map((group) => {
                    return (
                      <MenuItem key={group.id} value={group.id}>
                        {group.group_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          </div>
          <ColorButton
            variant="contained"
            sx={{ padding: "10px" }}
            onClick={uploadContact}
            disabled={isUploading}
          >
            {isUploading ? <Loader /> : 'Upload'}
          </ColorButton>
        </div>
      </div>
    </section>
  );
};

export default UploadContact;
