import { Box, Button, Card, CardContent, Grid, Skeleton, Stack, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import React, { useEffect, useState } from "react";
import { faqs, reasons, testimonials } from "../data";
import api from "../services/api";
import { HashLink as NavHashLink } from 'react-router-hash-link';
import heroImage from '../assets/hero-bg.jpg'
import aboutImage from '../assets/about.jpg'
import { ChevronRight } from "@mui/icons-material";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EmailIcon from '@mui/icons-material/Email';
import Slider from 'react-slick';

const HomePage = () => {
  const [isFetching, setIsFetching] = useState(false)
  // const [about, setAbout] = useState('')
  const [contacts, setContacts] = useState({
    address: "",
    email: "",
    phone: "",
  });
  useEffect(() => {
    setIsFetching(true)
    // api.admin.getAboutUs()
    //   .then((res) => {
    //     const aboutText = res.data['About-Us'][0].value;
    //     setAbout(aboutText);
    //   })
    //   .catch((err) => {
    //     console.error("Error fetching About Us content:", err);
    //   }).finally(() => setIsFetching(false))
    Promise.all([
      api.admin.getContactAddress(),
      api.admin.getContactEmail(),
      api.admin.getContactPhone(),
    ])
      .then(([addressRes, emailRes, phoneRes]) => {
        setContacts({
          address: addressRes.data['Contact-address'][0].value || "N/A",
          email: emailRes.data['Contact-email'][0].value || "N/A",
          phone: phoneRes.data['Contact-phone'][0].value || "N/A",
        });
      })
      .catch((err) => {
        console.error("Error getting contact information:", err);
      }).finally(() => setIsFetching(false))
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear"
  };

  return (
    <div className="bg-[white]">
      <section style={{
        color: 'black',
        backgroundImage: `url(${heroImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '400px',
      }}>
        <div className='px-2 md:px-[5%] xl:px-[10%]'>
          <div className="pt-[30px] md:pt-[80px]">
            <h2 className='font-extrabold text-[35px] md:text-[50px] text-[#2C4964]'>
              Welcome to Text&Voice
            </h2>
            <h3 className="text-[20px] font-medium text-[#434343]">
              Bulk sms and Robocalls at its best!!
            </h3>
          </div>
          <Grid container spacing={4} className="content" data-aos="zoom-out" data-aos-delay="200" sx={{ marginTop: '20px' }}>
            <Grid item xs={12} sm={6} lg={4}>
              <Box sx={{ backgroundColor: '#1977CC', color: 'white', padding: '20px', borderRadius: '15px' }}>
                <Typography variant="h3" sx={{ margin: '10px 0', fontWeight: '700', fontSize: '45px' }}>Why We're Your Best Choice</Typography>
                <Typography variant="body1">
                  <b>1. Reach anyone instantly:</b> Simplify communication with our Text & Voice service and send mass messages to staff, customers, and prospects quickly and easily.<br /><br />
                  <b>2. Effortless Mass Messaging:</b> Text & Voice keeps things simple, allowing you to send targeted messages to large audiences with ease.<br /><br />
                  <b>3. All-in-One Communication:</b> Streamline your communication with a single platform for Text & Voice messaging, saving you time and effort.
                </Typography>
                <Box textAlign="center">
                  <Button
                    color="inherit"
                    component={NavHashLink}
                    to="#about-us"
                    activeClassName="Mui-selected"
                    sx={{ mx: 1 }}
                    endIcon={<ChevronRight />}
                  >
                    Learn More
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </section>

      <section id="about-us" className="px-2 md:px-[5%] xl:px-[10%] py-16 flex flex-col lg:flex-row gap-[20px] w-full">
        <figure className="w-full h-full lg:w-[45%] mt-[30px]">
          <img src={aboutImage} alt="" className="w-full h-full lg:min-h-svh object-fill" />
        </figure>
        <div className="w-full lg:w-[55%] px-[25px]">
          <div>
            <h2 className="font-extrabold text-[#2C4964] text-2xl md:text-3xl my-8">
              About Us
            </h2>
            {isFetching ? (
              <Stack spacing={1}>
                <Skeleton variant="rectangular" width={'100%'} height={20} />
                <Skeleton variant="rectangular" width={'100%'} height={200} />
                <Skeleton variant="rectangular" width={'100%'} height={20} />
                <Skeleton variant="rectangular" width={'100%'} height={250} />
              </Stack>
            ) :
              <div className="flex flex-col gap-4 mx-auto text-[16px] text-gray-700">
                <p>Welcome to TEXTANDVOICE.NET, a product of DIGITALMEDIAPLUS ENTERPRISES, a dynamic and growing company officially registered with the Corporate Affairs Commission of Nigeria.</p>
                <p>
                  At TEXTANDVOICE.NET, we specialize in providing top-notch text and voice messaging solutions designed to streamline and enhance your communication efforts. Whether you're looking to send bulk SMS messages or execute effective robocall campaigns, our platform offers a simple, efficient, and reliable service to meet your needs.
                </p>
              </div>}
            {/* <div className="space-y-6 max-w-4xl mx-auto text-lg text-gray-700 leading-relaxed"
                dangerouslySetInnerHTML={{ __html: about }} />} */}
          </div>
          <div className="mt-[30px]">
            <ul className="flex flex-col gap-[25px]">
              <li>
                <article className="flex gap-3 items-start">
                  <BusinessCenterIcon sx={{ fontSize: '40px' }} className=" text-[#1977CC]" />
                  <div>
                    <Typography variant="h6" sx={{ fontWeight: '800' }} className="text-[#2C4964] pb-[10px]">Our Services</Typography>
                    <Typography variant="body1"><b>1. Bulk SMS:</b> Quickly reach your audience for marketing, notifications, and reminders.<br /><b>2. Robocalls:</b> Easily deliver automated voice messages for announcements and updates.</Typography>
                  </div>
                </article>
              </li>
              <li>
                <article className="flex gap-3 items-start">
                  <CreditCardIcon sx={{ fontSize: '40px' }} className=" text-[#1977CC]" />
                  <div>
                    <Typography variant="h6" sx={{ fontWeight: '800' }} className="text-[#2C4964] pb-[10px]">Payment Method</Typography>
                    <Typography variant="body1">We accept payments exclusively in espees for a fast and secure transaction process.</Typography>
                  </div>
                </article>
              </li>
              <li>
                <article className="flex gap-3 items-start">
                  <ThumbUpIcon sx={{ fontSize: '40px' }} className=" text-[#1977CC]" />
                  <div>
                    <Typography variant="h6" sx={{ fontWeight: '800' }} className="text-[#2C4964] pb-[10px]">Our Commitment</Typography>
                    <Typography variant="body1">DIGITALMEDIAPLUS ENTERPRISES provides high-quality, reliable communication solutions, dedicated to helping you achieve your goals efficiently.</Typography>
                  </div>
                </article>
              </li>
            </ul>
          </div>
        </div>

      </section>
      {/* stats */}
      <section id="stats" class="stats section bg-[#F1F7FC] my-[30px] py-[50px]">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap -mx-4">

            <div class="w-full md:w-1/2 lg:w-1/4 px-4 mb-12">
              <div class="bg-white p-6 rounded-lg text-center shadow-lg relative flex flex-col items-center">
                <div class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white inline-block px-3 py-2 rounded-full bg-blue-500 text-2xl ">
                  <i class="fas fa-paper-plane"></i>
                </div>
                <div class="mt-8">
                  <span class="text-3xl font-bold text-[#2C4964] ">100000</span>
                  <p class="mt-2 text-black">Messages Sent Daily</p>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/4 px-4 mb-12">
              <div class="bg-white p-6 rounded-lg text-center shadow-lg relative flex flex-col items-center">
                <div class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white inline-block px-3 py-2 rounded-full bg-blue-500 text-2xl">
                  <i class="far fa-hospital"></i>
                </div>
                <div class="mt-8">
                  <span class="text-3xl font-bold text-[#2C4964] ">765</span>
                  <p class="mt-2 text-black">Businesses Served</p>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/4 px-4 mb-12">
              <div class="bg-white p-6 rounded-lg text-center shadow-lg relative flex flex-col items-center">
                <div class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white inline-block px-3 py-2 rounded-full bg-blue-500 text-2xl">
                  <i class="fas fa-users"></i>
                </div>
                <div class="mt-8">
                  <span class="text-3xl font-bold text-[#2C4964] ">6532</span>
                  <p class="mt-2 text-black">Active Users</p>
                </div>
              </div>
            </div>

            <div class="w-full md:w-1/2 lg:w-1/4 px-4 mb-12">
              <div class="bg-white p-6 rounded-lg text-center shadow-lg relative flex flex-col items-center">
                <div class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white inline-block px-3 py-2 rounded-full bg-blue-500 text-2xl">
                  <i class="fas fa-clock"></i>
                </div>
                <div class="mt-8">
                  <span class="text-3xl font-bold text-[#2C4964] ">99</span>
                  <p class="mt-2 text-black">Uptime Percentage</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>


      {/* service */}
      <section id='service' className="bg-[white] px-2 md:px-[5%] xl:px-[10%] py-[40px]">
        <div className='my-[40px]'>
          <div className="flex flex-col items-center mb-8">
            <h2 className="text-center text-[#2C4964] font-semibold text-[30px] md:text-[45px] pb-[3px]">
              Why Choose Us?
            </h2>
            <div className='custom-underline w-[20%]'></div>
          </div>
          <p className='text-center text-[19px]'>Benefit from reliable, high-performance communication solutions with 24/7 support and unmatched efficiency.</p>
        </div>
        <Grid container spacing={8}>
          {reasons.map((reason, index) => (
            <Grid item xs={12} sm={6} md={5} lg={4} margin={'0 auto'} key={index}>
              <Card
                variant="outlined"
                sx={{
                  minWidth: '290px',
                  height: "350px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  color: "#2C4964",
                  fontWeight: '700',
                  transition: "background-color 0.3s, color 0.3s",
                  '&:hover': {
                    backgroundColor: "#1977CC",
                    color: "white",
                    cursor: 'pointer',
                    '& .icon': {
                      backgroundColor: "white",
                      color: "#10439F",
                    },
                  },
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <div style={{ marginBottom: "16px", position: "relative", display: "flex", alignItems: "center" }}>
                    <span style={{
                      position: "absolute",
                      top: "40%",
                      left: "-10px",
                      width: "60px",
                      height: "60px",
                      backgroundColor: "#5EA0DB",
                      borderRadius: "4px",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                    }}></span>
                    <div className="icon flex items-center justify-center rounded-sm text-white bg-[#10439F] p-3 text-2xl w-[60px] h-[60px]" style={{ position: "relative", zIndex: 2 }}>
                      {reason.icon}
                    </div>
                  </div>
                  <Typography variant="h6" component="h4" sx={{ fontWeight: '700' }} gutterBottom>
                    {reason.title}
                  </Typography>
                  <Typography variant="body1" component="p" sx={{fontSize:'14px'}}>
                    {reason.summary}
                  </Typography>
                </CardContent>
              </Card>

            </Grid>
          ))}
        </Grid>
      </section>

      <section className="bg-[#F1F7FC] px-2 md:px-[5%] xl:px-[10%] py-[40px]">
        <div className='my-[40px]'>
          <div className="flex flex-col items-center my-8">
            <h2 className="text-center text-[#2C4964] font-semibold text-[30px] md:text-[45px] pb-[3px]">
              Frequently Asked Questions
            </h2>
            <div className='custom-underline w-[20%]'></div>
          </div>
          <p className='text-center text-[19px]'>Find answers to common queries about our messaging services, including how to use our platform, security measures, and support options.</p>
        </div>
        <Box sx={{ width: '100%', margin: 'auto' }}>
          {faqs.map((faq, index) => (
            <Accordion
              key={index}
              sx={{
                backgroundColor: 'white',
                color: '#2C4964',
                padding: '10px 0',
                marginBottom: '15px',
                transition: 'background-color 0.3s, color 0.3s',
                '&.Mui-expanded': {
                  backgroundColor: '#10439F',
                  color: 'white',
                },
                '&:not(.Mui-expanded):hover .MuiAccordionSummary-root': {
                  color: '#10439F',
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ChevronRight sx={{ color: 'inherit' }} />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{
                  '&.Mui-expanded': {
                    minHeight: '48px',
                    color: 'white',
                  },
                  '&.Mui-focused': {
                    backgroundColor: 'inherit',
                    color: 'white',
                  },
                }}
              >
                <Typography sx={{ fontWeight: 'bold' }}>{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

      </section>

      <section className="bg-[white] px-2 md:px-[5%] xl:px-[10%] py-[40px] flex flex-col lg:flex-row gap-[20px]">
        <div className="w-full lg:w-[50%]">
          <h2 className="text-[#2C4964] text-[35px] pb-3 font-extrabold">Testimonials</h2>
          <p>Discover how our clients from various industries have transformed their communication strategies with TEXTANDVOICE.NET. Hear firsthand about their experiences and the impact our platform has had on their messaging efficiency and effectiveness.</p>
        </div>
        <div className="w-full lg:w-[50%] shadow-md relative pb-[30px]">
          <Slider {...settings} >
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-white p-6 rounded-lg flex flex-col md:flex-row items-center">
                <article className="flex gap-3">
                  <div className="flex gap-3 flex-shrink-0 mb-8 md:mr-4">
                    <img className="w-20 h-20 rounded-full object-cover" src={testimonial.image} alt={testimonial.name} />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold">{testimonial.name}</h3>
                    <p className="text-gray-500">{testimonial.title}</p>
                    <div className="flex justify-start mt-2">
                      {[...Array(testimonial.rating)].map((_, i) => (
                        <svg
                          key={i}
                          className="w-5 h-5 text-yellow-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.203 3.686a1 1 0 00.95.69h3.862c.969 0 1.371 1.24.588 1.81l-3.124 2.261a1 1 0 00-.364 1.118l1.203 3.686c.3.921-.755 1.688-1.54 1.118L10 13.347l-3.124 2.261c-.784.57-1.838-.197-1.54-1.118l1.203-3.686a1 1 0 00-.364-1.118L3.051 8.113c-.783-.57-.38-1.81.588-1.81h3.862a1 1 0 00.95-.69l1.203-3.686z" />
                        </svg>
                      ))}
                    </div>
                  </div>
                </article>
                <div className="text-center md:text-left">
                  <p className="mt-4 text-gray-700 italic">{testimonial.text}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <section id='contact' className="bg-[white] px-2 md:px-[5%] lg:px-[15%] py-[40px]">
        <div className='my-[40px]'>
          <div className="flex flex-col items-center mb-8">
            <h2 className=" text-center text-[#2C4964] font-bold text-[30px] md:text-[45px] pb-[3px]">
              Contact
            </h2>
            <div className='custom-underline w-[10%]'></div>
          </div>
          <p className='text-center text-[19px]'>We're here to help with any questions or support you need. Reach out to our team for assistance, inquiries, or feedback, and we'll ensure you receive prompt and effective support.</p>
        </div>
        <Box sx={{ width: '100%' }}>
          <ul className="flex flex-wrap justify-between gap-4 my-4">
            <li className="text-[#2C4964] flex gap-3">
              <div className="bg-blue-500 px-3 flex items-center text-white rounded-full">
                <KeyboardArrowDownIcon />
              </div>
              <article>
                <h4 className='font-bold text-xl'>Kingschat</h4>
                <p className='text-black'>@DMPLUS</p>
              </article>
            </li>
            {/* <li className="text-[#2C4964]">
              {isFetching ? (<Skeleton animation='wave' width={'100%'} />) : (
                <article>
                  <h4 className='font-bold text-xl'>Address</h4>
                  <address className='text-black'>{contacts.address}</address>
                </article>
              )}
            </li> */}
            <li className="text-[#2C4964] flex gap-3">
              <div className="bg-blue-500 px-3 flex items-center text-white rounded-full">
                <EmailIcon />
              </div>
              {isFetching ? (<Skeleton animation='wave' width={'100%'} />) : (
                <article>
                  <h4 className='font-bold text-xl'>Text Us</h4>
                  <a className='text-black' href={`tel:${contacts.phone}`}>{contacts.phone}</a>
                </article>
              )}
            </li>
            <li className="text-[#2C4964] flex gap-3">
              <div className="bg-blue-500 px-3 flex items-center text-white rounded-full">
                <EmailIcon />
              </div>
              {isFetching ? (<Skeleton animation='wave' width={'100%'} />) : (
                <article>
                  <h4 className='font-bold text-xl'>Email Us</h4>
                  <a className='text-black' href={`mailto:${contacts.email}`}>{contacts.email}</a>
                </article>)}
            </li>
          </ul>
        </Box>
      </section>

    </div>
  );
};

export default HomePage;
