import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import api from "../../services/api";
import { toast } from "react-toastify";
import Spinner from "../../Components/Spinner/Spinner";

const SenderIDRequestForm = () => {
  const [senderID, setSenderID] = useState("");
  const [senderIDsList, setSenderIDsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Function to handle adding sender ID to the list
  const handleAddSenderID = () => {
    if (senderID.trim() !== "") {
      if (senderID.trim().length > 11) {
        toast.error("Sender ID cannot be more than 11 characters.");
      } else {
        setSenderIDsList([...senderIDsList, senderID.trim()]);
        setSenderID("");
      }
    }
  };

  // Function to handle form submission (not changed from previous example)
  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (senderIDsList.length < 1) {
      toast.error("Please add a sender ID");
      setIsLoading(false);
      return;
    }

    const formData = new FormData();

    senderIDsList.forEach((senderId, index) => {
      formData.append(`sender_ids[${index}]`, senderId);
    });

    api.campaign
      .senderId(formData)
      .then((res) => {
        toast.success(res.data?.message);
        alert(
          "Your sender id request has been received will be approved or disapproved shortly"
        );
        setSenderIDsList([]);
      })
      .catch((res) => console.log(res))
      .finally(() => setIsLoading(false));
  };

  const charactersLeft = 11 - senderID.length;

  return (
    <section className="w-full md:w-[85%] p-6 mx-auto">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Sender ID"
              variant="outlined"
              value={senderID}
              onChange={(e) => {
                if (e.target.value.length <= 11) {
                  setSenderID(e.target.value);
                }
              }}
              helperText="SENDER IDs for Bulksms. Sender ID request is free and must not be more than 11 characters, space inclusive. You can request as many sender IDs as you require for your campaigns. It takes between 10mins to 24hrs for approval of  Sender IDs."
              inputProps={{ maxLength: 11 }}
            />
            <p>
              Characters Left:
              <span
                className={
                  senderID.length === 11
                    ? "text-green-500"
                    : charactersLeft <= 5
                    ? "text-red-500"
                    : "text-green-500"
                }
              >
                {charactersLeft}
              </span>
              /11
            </p>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddSenderID}
            >
              Add
            </Button>
          </Grid>
          <Grid item xs={12}>
            <List>
              {senderIDsList.map((senderID, index) => (
                <ListItem key={index}>
                  {index + 1}. <ListItemText primary={senderID} />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              color="primary"
              sx={{ padding: "10px" }}
            >
              {isLoading ? <Spinner /> : "Submit"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </section>
  );
};

export default SenderIDRequestForm;
