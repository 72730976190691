import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import api from "../../../services/api";
import ReactPaginate from "react-paginate";
import MessageModal from "../../../Components/MessageModal";

function MessageUsers() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [sendingMessage, setIsSendingMessage] = useState(false);
  const [sendGeneral, setSendGeneral] = useState(false);
  const [user, setUser] = useState("");

  useEffect(() => {
    setIsFetching(true);
    const fetchUsers = async () => {
      try {
        const activeUsersData = await api.admin.getActiveUsers();
        const inactiveUsersData = await api.admin.getInActiveUsers();

        const active = activeUsersData.data["active_users"];
        const inactive = inactiveUsersData.data["inactive_users"];
        setUsers([...active, ...inactive]);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchUsers();
  }, []);

  const FilteredUsers = users.filter((user) =>
    (user?.username?.toLowerCase() || "").includes(search.toLowerCase())
  );

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const pageCount = Math.ceil(FilteredUsers.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = FilteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  const handleItems = (e) => {
    setItemsPerPage(e.target.value);
  };

  return (
    <section className="w-full md:w-[95%]">
      <div className="my-4">
        <Typography variant="h4">Message Users</Typography>
      </div>
      <div className="my-4 w-full md:w-[50%]">
        <Button
          variant="contained"
          color={"primary"}
          onClick={() => setSendGeneral(!sendGeneral)}
        >
          {"Send General Message"}
        </Button>
        {sendGeneral && (
          <MessageModal
            open={sendGeneral}
            onClose={() => setSendGeneral(false)}
          />
        )}
      </div>
      <div className="my-4 w-full md:w-[65%] mx-auto">
        <input
          type="search"
          value={search}
          name="search"
          id="search"
          onChange={(e) => setSearch(e.target.value)}
          className="border-2 border-[#10439F] rounded-md outline-none p-3 bg-transparent w-full"
          placeholder="Search for user by their username..."
        />
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S/N</TableCell>
              <TableCell>Profile Picture</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          {isFetching ? (
            <TableRow>
              <TableCell colSpan={10} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            <TableBody className="w-full overflow-x-auto">
              {currentItems.map((user, index) => (
                <TableRow key={user.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <img
                      src={
                        user.avatar ||
                        "https://imgs.search.brave.com/J5-KJNoclGIgO9mgbMuULm8xw_ri-hvqZYOyhc50Q64/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly90NC5m/dGNkbi5uZXQvanBn/LzAyLzE3LzM0LzY3/LzM2MF9GXzIxNzM0/Njc4Ml83WHBDVHQ4/YkxOSnF2VkFhRFpK/d3Zaam0wZXBRbWo2/ai5qcGc"
                      }
                      alt={user.name}
                      className="w-10 h-10 rounded-full"
                    />
                  </TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user?.username || "N/A"}</TableCell>
                  <TableCell>{user?.email || "N/A"}</TableCell>
                  <TableCell>{user?.phone_number || "N/A"}</TableCell>
                  <TableCell>{user?.gender || "N/A"}</TableCell>
                  <TableCell>{user.status}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color={"primary"}
                      onClick={() => {
                        setIsSendingMessage(true);
                        setUser(user);
                      }}
                    >
                      {"Message User"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <div>
        <select
          name="items"
          value={itemsPerPage}
          id="itemsperpage"
          onChange={handleItems}
          className="m-4 border border-[#1977CC]"
        >
          <option value={20}>20</option>
          <option value={40}>40</option>
          <option value={60}>60</option>
        </select>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"flex justify-center gap-2 my-12"}
          previousLinkClassName={
            "rounded-full border px-4 py-2 mr-2 bg-red-500 text-white"
          }
          nextLinkClassName={
            "rounded-full border px-4 py-2 ml-2 bg-asparagus-500 text-[#1977CC]"
          }
          breakClassName={"text-blue-500 cursor-pointer"}
          activeClassName={"bg-blue-500 text-white border rounded-full p-2"}
        />
      </div>
      {sendingMessage && (
        <MessageModal
          user={user}
          open={sendingMessage}
          onClose={() => setIsSendingMessage(false)}
        />
      )}
    </section>
  );
}

export default MessageUsers;
