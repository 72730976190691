import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
} from "@mui/material";
import { PlayArrow } from "@mui/icons-material";

const PastRecordingsModal = ({
  open,
  onClose,
  recordings,
  onSelectRecording,
}) => {
  const [recordingsWithMetadata, setRecordingsWithMetadata] = useState([]);

  useEffect(() => {
    const fetchMetadata = async () => {
      const updatedRecordings = await Promise.all(
        recordings?.map((recording, index) => {
          return new Promise((resolve) => {
            const audio = new Audio(recording.audioFileUrl);
            audio.addEventListener("loadedmetadata", () => {
              resolve({
                ...recording,
                name: `Recording ${index + 1}`,
                duration: audio.duration,
              });
            });
            audio.addEventListener("error", (error) => {
              resolve({
                ...recording,
                name: `Recording ${index + 1}`,
                duration: "Error",
              });
            });
          });
        })
      );
      setRecordingsWithMetadata(updatedRecordings);
    };

    fetchMetadata();
  }, [recordings]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const formatDuration = (duration) => {
    if (duration === "Error") return "Error loading duration";
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Past Recordings
        </Typography>
        <List>
          {recordingsWithMetadata?.map((recording, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  onClick={() => {
                    const audio = new Audio(recording.audioFileUrl);
                    audio.play();
                  }}
                >
                  <PlayArrow />
                </IconButton>
              }
            >
              <ListItemText
                primary={recording.name}
                secondary={`Duration: ${formatDuration(recording.duration)}`}
              />
              <Button onClick={() => onSelectRecording(recording.audioFileUrl)}>
                Select
              </Button>
            </ListItem>
          ))}
        </List>
      </Box>
    </Modal>
  );
};

export default PastRecordingsModal;
