import axios from "axios";
import routes from "../routes";

export const auth = {
 register: async (postBody) => {
  return await axios.post(routes.register(), postBody);
 },
 login: async (postBody) => {
  return await axios.post(routes.login(), postBody);
 },
 adminLogin: async (postBody) => {
  return await axios.post(routes["admin-login"](), postBody);
 },
 changePassword: async (postBody) => {
  return await axios.post(routes["change-password"](), postBody)
 },
};
