import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import ReactPaginate from "react-paginate";

const ApproveVirtualNumber = () => {
  const [virtualNumbers, setvirtualNumbers] = useState([]);
  const [virtual, setVirtual] = useState({
    number: '',
    userId: ''
  });
  const [loading, setLoading] = useState(true);
  const [approving, setApproving] = useState(false);

  const handleVirtual = (e) => {
    const { name, value } = e.target
    setVirtual((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  useEffect(() => {
    api.admin.getVirtualNumbers()
      .then(res => {
        setvirtualNumbers(res.data['virtual-Number']);
      })
      .catch(err => {
        console.error("Error fetching sender IDs:", err);
        toast.error("Failed to load sender IDs.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const ApproveVirtualNumber = () => {
    setApproving(true)
    const formData = new FormData()
    formData.append('virtual_num', virtual.number)

    if (!virtual.number) {
      toast.error("Kindly input User's number")
      setApproving(false);
      return
    }
    if (!virtual.userId) {
      toast.error("Kindly input User's ID")
      setApproving(false);
      return
    }
    api.admin.approveVirtualNumber(virtual.userId, formData)
      .then(res => {
        setVirtual({
          number: '',
          userId: ''
        })
        toast.success(res.data.message);
      })
      .catch(err => {
        console.error("Error approving virtual number:", err);
        toast.error(err.response.data?.message);
      })
      .finally(() => {
        setApproving(false);
      });
  }

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const pageCount = Math.ceil(virtualNumbers.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = virtualNumbers.slice(indexOfFirstItem, indexOfLastItem);

  const handleItems = (e) => {
    setItemsPerPage(e.target.value)
  }

  const handleDelete = (id) => {
    setLoading(true)
    api.admin.deleteVirtualNumber(id).then(res => {
      toast.success('Virtual Number deleted successfully');
      setvirtualNumbers((prev) =>
        prev.filter((num) => num.id !== id)
      );
    }).catch(res => console.log(res)).finally(() => setLoading(false))
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>Assign Virtual Number</Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ margin: '20px 0' }}>
          <div className='mb-6'>
            <Typography variant="p" sx={{ fontSize: '20px' }} gutterBottom>
              Input Virtual Number and User's ID
            </Typography>
            <div className='flex flex-col lg:flex-row gap-3 w-full mt-4'>
              <TextField
                label="Virtual Number"
                variant="outlined"
                name="number"
                value={virtual.number}
                onChange={handleVirtual}
                fullWidth
                required
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="User ID"
                variant="outlined"
                name="userId"
                value={virtual.userId}
                onChange={handleVirtual}
                fullWidth
                required
                sx={{ marginBottom: 2, width: '40%' }}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={ApproveVirtualNumber}
            >
              {approving ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Approve'}
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S/N</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Virtual Number</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems.map((sender, idx) => (
                  <TableRow key={sender.id}>
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>{sender.user_id}</TableCell>
                    <TableCell>{sender?.username || 'N/A'}</TableCell>
                    <TableCell>{sender.virtual_num}</TableCell>
                    <TableCell>{sender.status}</TableCell>
                    <TableCell>{new Date(sender.created_at).toLocaleString()}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDelete(sender.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <select name="items" value={itemsPerPage} id="itemsperpage" onChange={handleItems} className="m-4 border border-[#1977CC]">
              <option value={20}>20</option>
              <option value={40}>40</option>
              <option value={60}>60</option>
            </select>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"flex justify-center gap-2 my-12"}
              previousLinkClassName={
                "rounded-full border px-4 py-2 mr-2 bg-red-500 text-white"
              }
              nextLinkClassName={
                "rounded-full border px-4 py-2 ml-2 bg-asparagus-500 text-[#1977CC]"
              }
              breakClassName={"text-blue-500 cursor-pointer"}
              activeClassName={
                "bg-blue-500 text-white border rounded-full p-2"
              }
            />
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default ApproveVirtualNumber;
