import React, { useEffect, useState } from "react";
import api from "../../services/api";
import EmptyState from "../../Components/EmptyState";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import Loader from "../../Components/Loader";
import ReactPaginate from "react-paginate";
const TransactionHistory = () => {
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    api.campaign
      .getTransactionHistory()
      .then((res) => setHistory(res.data?.transactions))
      .catch((res) => console.log(res))
      .finally(() => setIsLoading(false));
  }, []);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const pageCount = Math.ceil(history.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = history.slice(indexOfFirstItem, indexOfLastItem);

  const handleItems = (e) => {
    setItemsPerPage(e.target.value)
  }

  return (
    <section className="w-full md:w-[85%] p-6 mx-auto">
      <div>
        <h2 className="font-semibold text-lg">Transaction History</h2>
        <p className="text-[#6f7070]">An overview of your transactions</p>
      </div>
      <div>
        {isLoading ? (
          <div className="flex justify-center items-center my-8 w-full">
            <Loader />
          </div>
        ) : history.length < 1 ? (
          <div className="flex justify-center">
            <EmptyState
              to={"/dashboard/buy-sms-online"}
              stateMessage={"No message transactions yet"}
              buttonMessage={"Recharge now"}
            />
          </div>
        ) : (
          <TableContainer component={Paper} sx={{ margin: "10px 0" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S/N</TableCell>
                  <TableCell>Payment Reference</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Transaction Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems.map((transaction, idx) => (
                  <TableRow key={transaction.id}>
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>{transaction.payment_ref}</TableCell>
                    <TableCell>{transaction.price}</TableCell>
                    <TableCell>{transaction.status}</TableCell>
                    <TableCell>{transaction.transaction_date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <select name="items" value={itemsPerPage} id="itemsperpage" onChange={handleItems} className="m-4 border border-[#1977CC]">
              <option value={20}>20</option>
              <option value={40}>40</option>
              <option value={60}>60</option>
            </select>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"flex justify-center gap-2 my-12"}
              previousLinkClassName={
                "rounded-full border px-4 py-2 mr-2 bg-red-500 text-white"
              }
              nextLinkClassName={
                "rounded-full border px-4 py-2 ml-2 bg-asparagus-500 text-[#1977CC]"
              }
              breakClassName={"text-blue-500 cursor-pointer"}
              activeClassName={
                "bg-blue-500 text-white border rounded-full p-2"
              }
            />
          </TableContainer>
        )}
      </div>
    </section>
  );
};

export default TransactionHistory;
