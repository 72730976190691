import React, { useEffect, useState } from "react";
import {
 Box,
 Button,
 TextField,
 Typography,
 CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../services/api";

const SetSMSUnitPrice = () => {
 const [price, setPrice] = useState("");
 const [loading, setLoading] = useState(false);
 const [isFetching, setIsFetching] = useState(false);

 useEffect(() => {
  setIsFetching(true)
  api.admin.getSMSUNITPRICE()
    .then((res) => {
      setPrice(res.data['SMS-UNIT-PRICE'][0].value);
    })
    .catch((err) => {
      toast.error("Failed to get Unit Price.");
      console.error("Error getting Unit Price:", err);
    })
    .finally(() => {
      setIsFetching(false);
    });
}, [])

 const handleInputChange = (e) => {
  setPrice(e.target.value);
 };

 const handleSubmit = (e) => {
  e.preventDefault();
  setLoading(true);

  const formData = new FormData();
  formData.append("price", price);

  api.admin.setSMSUNITPRICE(formData)
   .then((res) => {
    console.log(res)
    toast.success("Unit Price updated successfully!");
    setPrice("");
   })
   .catch((err) => {
    toast.error("Failed to update Unit Price.");
    console.error("Error updating Unit Price:", err);
   })
   .finally(() => {
    setLoading(false);
   });
 };

 return (
  <Box
   component="form"
   onSubmit={handleSubmit}
   sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 400,
    margin: "0 auto",
    padding: 2,
    boxShadow: 3,
    borderRadius: 1,
    backgroundColor: "white",
   }}
  >
   <Typography variant="h5" component="h2" gutterBottom>
    Set SMS Unit Price
   </Typography>
   {isFetching ? <CircularProgress /> :<TextField
    label="Unit Price"
    variant="outlined"
    name="price"
    value={price}
    onChange={handleInputChange}
    fullWidth
    required
    sx={{ marginBottom: 2 }}
   />}
   <Button type="submit" variant="contained" color="primary" disabled={loading}>
    {loading ? <CircularProgress size={24} /> : "Submit"}
   </Button>
  </Box>
 );
};

export default SetSMSUnitPrice