import { useState, useEffect, useCallback } from 'react';
import api from '../services/api';
import MaintenancePage from './MaintenanceMode';


const MaintenanceChecker = ({ children }) => {
 const [inMaintenanceMode, setInMaintenanceMode] = useState(null);
 const [maintenanceMessage, setMaintenanceMessage] = useState('');

 const checkMaintenanceStatus = useCallback(() => {
  api.admin
   .getMaintenanceStatus()
   .then((res) => {
    const value = res.data.maintenance_mode[0].value;
    const parsedValue = JSON.parse(value);
    setMaintenanceMessage(parsedValue.message);

    if (parsedValue.maintenance_mode === "1") {
     setInMaintenanceMode(true); // Set maintenance mode
    } else {
     setInMaintenanceMode(false); // Disable maintenance mode
    }
   })
   .catch(console.error);
 }, []);

 useEffect(() => {
  // Check maintenance status on component mount
  checkMaintenanceStatus();

  // Set up polling to check every 10 seconds (or any interval you choose)
  const interval = setInterval(checkMaintenanceStatus, 10000);

  // Cleanup interval on component unmount
  return () => clearInterval(interval);
 }, [checkMaintenanceStatus]);

 if (inMaintenanceMode === null) {
  return <div className="flex items-center justify-center h-screen bg-gray-800">
   <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-blue-400 mx-auto"></div>
  </div>;
 }

 return inMaintenanceMode ? (
  <MaintenancePage message={maintenanceMessage} />
 ) : (
  children
 );
};

export default MaintenanceChecker;
