import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Loader from "../../../Components/Loader";

const PhoneBook = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const [newContact, setNewContact] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [newGroup, setNewGroup] = useState({
    group_name: "",
    description: "",
  });

  const [viewingSingle, setViewingSingle] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [allGroups, setAllGroups] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  console.log(allGroups)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openNewContact = () => {
    setOpenContact(true);
  };
  const closeNewContact = () => {
    setOpenContact(false);
  };

  const handleEditClick = (group) => {
    setNewGroup({
      group_name: group.group_name,
      description: group.description,
    });
    setSelectedGroup(group);
    setIsEditing(true);
    setOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);
    api.contacts
      .getGroup()
      .then((res) => {
        setAllGroups(res.data?.groups);
      })
      .catch((res) => console.log(res))
      .finally(() => setIsLoading(false));
  }, [isCreating]);

  useEffect(() => {
    setIsLoading(true);
    if (selectedGroup !== null)
      api.contacts
        .getContactForSingleGroup(selectedGroup?.id)
        .then((res) => {
          setContacts(res.data?.contacts);
        })
        .catch((res) => console.log(res))
        .finally(() => setIsLoading(false));
  }, [selectedGroup, isCreating]);

  const handleCreate = () => {
    setIsCreating(true);

    if (newGroup.group_name === "") {
      toast.error("Please provide a group name.");
      setIsCreating(false);
      return;
    }
    const formData = new FormData();
    formData.append("group_name", newGroup.group_name);
    formData.append("description", newGroup.description);
    if (isEditing === true) {
      api.contacts
        .updateGroup(selectedGroup.id, newGroup)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Group Edit");
            setViewingSingle(false);
            setOpen(false);
            setIsEditing(false);
            setNewGroup({
              group_name: "",
              description: "",
            });
          }
        })
        .catch((res) => console.log(res))
        .finally(() => setIsCreating(false));
    } else {
      api.contacts
        .createGroup(formData)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Group Created");
            setOpen(false);
          }
        })
        .catch((res) => console.log(res))
        .finally(() => setIsCreating(false));
    }
  };

  const handleCreateContact = () => {
    setIsCreating(true);

    if (!newContact.phone) {
      toast.error("Please provide a phone number.");
      setIsCreating(false);
      return;
    }
    const formData = new FormData();
    // formData.append("name", newContact.name);
    formData.append("phone", newContact.phone);
    // formData.append("email", newContact.email);

    if(isEditing === true){
      api.contacts
        .updateContact(selectedContact?.id, newContact)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            toast.success("Contact updated successfully");
            setOpenContact(false);
          }
        })
        .catch((res) => console.log(res))
        .finally(() => setIsCreating(false));
    }else{
      api.contacts
        .createContactWithinGroup(selectedGroup?.id, formData)
        .then((res) => {
          if (res.status === 201) {
            toast.success("Contact created successfully");
            setOpenContact(false);
          }
        })
        .catch((res) => console.log(res))
        .finally(() => setIsCreating(false));
    }

  };

  const deleteContact = (contactId) => {
    setIsCreating(true);
    api.contacts
      .deleteContact(contactId)
      .then((res) => {
        toast.success(res.data?.message);
        setContacts((prevContacts) =>
          prevContacts.filter((contact) => contact.id !== contactId)
        );
      })
      .catch((res) => console.log(res))
      .finally(() => setIsCreating(false));
  };

  const handleDeleteGroup = (groupId) => {
    setIsCreating(true);
    api.contacts
      .deleteGroup(groupId)
      .then((res) => {
        toast.success(res.data?.message);
        setAllGroups((prevGroups) =>
          prevGroups.filter((group) => group.id !== groupId)
        );
      })
      .catch((res) => console.log(res))
      .finally(() => setIsCreating(false));
  };

  const updateContact = (contact) => {
    setSelectedContact(contact)
    setIsEditing(true);
    setOpenContact(true);
    setNewContact({
      name: contact.name,
      phone: contact.phone,
      email: contact.email,
    });
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    padding: "10px 0",
    "&:hover": {
      backgroundColor: "#4bb5ff",
      color: "white",
    },
  }));
  const handleVisibilityClick = (group) => {
    setSelectedGroup(group);
    setViewingSingle(true);
  };
  const handleBackClick = () => {
    setViewingSingle(false);
    setSelectedGroup(null);
  };

  return (
    <section className="w-full md:w-[85%] p-6 mx-auto">
      <div>
        <h2 className="font-semibold text-lg">Phonebook</h2>
        <p className="text-[#6f7070]">
          An overview of your contacts and groups
        </p>
      </div>
      {!viewingSingle ? (
        <>
          <TableContainer
            component={Paper}
            sx={{ padding: 3, margin: "20px 0" }}
          >
            <div className="flex flex-col gap-3 ml-4">
              <Typography variant="h5">Contact groups</Typography>
              <div>
                <ColorButton
                  variant="contained"
                  sx={{ padding: 1 }}
                  onClick={handleClickOpen}
                >
                  Create New
                </ColorButton>
              </div>
            </div>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>S/N</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <div className="flex justify-center items-center my-8 w-full">
                      <Loader />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <TableBody>
                  {allGroups.map((group, index) => (
                    <TableRow
                      key={group.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {group.group_name} ({group.contacts_count})
                      </TableCell>
                      <TableCell>
                        <div>
                          <IconButton
                            aria-label="view"
                            onClick={() => handleVisibilityClick(group)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton aria-label="delete" onClick={()=>handleDeleteGroup(group.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </>
      ) : (
        <TableContainer component={Paper} sx={{ padding: 3, margin: "20px 0" }}>
          <Typography variant="h5" sx={{ marginLeft: 2 }}>
            {selectedGroup.group_name}
          </Typography>
          <Typography variant="subtitle1">
            {selectedGroup.description}
          </Typography>
          <div className="flex gap-4 items-center my-4">
            <IconButton aria-label="back" onClick={handleBackClick}>
              <KeyboardArrowLeftIcon />
            </IconButton>
            <ColorButton
              variant="contained"
              startIcon={<EditIcon />}
              sx={{ padding: 1, fontSize: "12px" }}
              onClick={() => handleEditClick(selectedGroup)}
            >
              Edit
            </ColorButton>
            <ColorButton
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ padding: 1, fontSize: "12px" }}
              onClick={openNewContact}
            >
              Add Contact
            </ColorButton>
          </div>
          <Table sx={{ minWidth: 650 }} aria-label="contact detail table">
            <TableHead>
              <TableRow>
                <TableCell>S/N</TableCell>
                <TableCell>CONTACT</TableCell>
                <TableCell>ACTION</TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <div className="flex justify-center items-center my-8 w-full">
                    <Loader />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {selectedGroup &&
                  contacts.map((contact, index) => (
                    <TableRow key={contact.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{contact.phone}</TableCell>
                      <TableCell>
                        <div>
                          <IconButton aria-label="delete">
                            <DeleteIcon
                              onClick={() => deleteContact(contact.id)}
                            />
                          </IconButton>
                          <IconButton
                            aria-label="update"
                            onClick={() => updateContact(contact)}
                          >
                            <EditIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}
      {/* create new group */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {isEditing ? "Edit your Group details" : "Create New Contact Group"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="group_name"
            label="Group Name"
            type="text"
            fullWidth
            variant="standard"
            value={newGroup.group_name}
            onChange={(e) =>
              setNewGroup((prev) => ({ ...prev, group_name: e.target.value }))
            }
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Group Description"
            type="text"
            fullWidth
            variant="standard"
            value={newGroup.description}
            onChange={(e) =>
              setNewGroup((prev) => ({ ...prev, description: e.target.value }))
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleCreate} disabled={isCreating}>
            {isCreating ? <Loader /> : isEditing ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* creat new contact */}
      <Dialog open={openContact} onClose={closeNewContact}>
        <DialogTitle>{isEditing ? "Edit your Contact details" : 'Create New Contact'}</DialogTitle>
        <DialogContent>
          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Contact Name"
            type="text"
            fullWidth
            variant="standard"
            value={newContact.name}
            onChange={(e) =>
              setNewContact((prev) => ({ ...prev, name: e.target.value }))
            }
          /> */}
          <TextField
            autoFocus
            margin="dense"
            id="phone"
            label="Contact Number"
            type="tel"
            fullWidth
            variant="standard"
            value={newContact.phone}
            onChange={(e) =>
              setNewContact((prev) => ({ ...prev, phone: e.target.value }))
            }
          />
          {/* <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            variant="standard"
            value={newContact.email}
            onChange={(e) =>
              setNewContact((prev) => ({ ...prev, email: e.target.value }))
            }
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeNewContact}>Cancel</Button>
          <Button onClick={handleCreateContact} disabled={isCreating}>
            {isCreating ? <Loader /> : isEditing ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default PhoneBook;
