const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="px-2 md:px-[5%] lg:px-[15%] py-[25px] bg-[#F1F7FC] ">
      <div>
        <p className="text-[#444444] my-2 text-center">
          © {year} Powered by DIGITALMEDIAPLUS. All rights reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
