import React, { useState } from "react";
import {
  Tabs,
  Tab,
  Button,
  Box,
  TextField,
  Skeleton,
  Modal,
} from "@mui/material";
import ReactPaginate from "react-paginate";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import api from "../../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import MessageModal from "../../../Components/MessageModal";
import RejectionModal from "../../../Components/RejectionModal";
import { toast } from "react-toastify";

const SingleUser = () => {
  const { id } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate()

  const [rejectingSenderId, setRejectingSenderId] = useState(false);
  const [senderId, setSenderId] = useState(null);
  const [rejecting, setIsRejecting] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const [userDetails, setUserDetails] = useState({
    campaigns: [],
    voiceReports: [],
    senderIDs: [],
    ownNumbers: [], // assuming you get ownNumbers separately
    virtualNumbers: [],
    transactions: [],
    name: "",
    email: "",
    espee_balance: 0,
    created_at: "",
    last_login: "",
    status: "",
    photo: ''
  });

  const [approving, setApproving] = useState(false);
  const [virtual, setVirtual] = useState({
    number: "",
    userId: id,
  });
  const handleVirtual = (e) => {
    const { name, value } = e.target;
    setVirtual((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const ApproveVirtualNumber = () => {
    setApproving(true);
    const formData = new FormData();
    formData.append("virtual_num", virtual.number);

    if (!virtual.number) {
      toast.error("Kindly input User's number");
      setApproving(false);
      return;
    }
    api.admin
      .approveVirtualNumber(virtual.userId, formData)
      .then((res) => {
        setVirtual({
          number: "",
          userId: id,
        });
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.error("Error approving virtual number:", err);
        toast.error(err.response.data?.message);
      })
      .finally(() => {
        setApproving(false);
      });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const handleOpenModal = (campaign) => {
    setSelectedCampaign(campaign);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCampaign(null);
  };
  const [sendingMessage, setIsSendingMessage] = useState(false);
  const user = {
    id: id,
  };

  const [espee, setEspee] = useState({
    number: "",
    userId: id,
    payment_ref: "",
  });
  const [assigning, setAssigning] = useState(false);

  const handleEspeeChange = (e) => {
    const { name, value } = e.target;
    setEspee((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const Assign = () => {
    setAssigning(true);
    const formData = new FormData();
    formData.append("espees", espee.number);
    formData.append("payment_ref", espee.payment_ref);

    if (!espee.number) {
      toast.error("Kindly input ESPEES amount");
      setAssigning(false);
      return;
    }
    if (!espee.userId) {
      toast.error("Kindly input User's ID");
      setAssigning(false);
      return;
    }
    
    api.admin
      .assignESP(espee.userId, formData)
      .then((res) => {
        setEspee({
          number: "",
          userId: id,
          payment_ref: "",
        });
        window.location.reload();
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.error("Error approving virtual number:", err);
        toast.error(err.response.data?.message);
      })
      .finally(() => {
        setAssigning(false);
      });
  };

  const handleApprove = (id) => {
    setSenderId(id);
    api.admin
      .approveID(id)
      .then((res) => {
        toast.success("Sender ID approved successfully!");
      })
      .catch((err) => {
        toast.error("Failed to approve sender ID.");
      })
      .finally(() => {
        setSenderId(null);
      });
  };

  const handleReject = (id) => {
    setSenderId(id);
    setIsRejecting(true);
  };

  const handleSubmitRejection = (reason) => {
    setRejectingSenderId(true);
    api.admin
      .rejectID(senderId, { reason })
      .then((res) => {
        toast.success("Sender ID rejected successfully!");
        setIsModalOpen(false);
      })
      .catch((err) => {
        console.error("Error rejecting sender ID:", err);
        toast.error("Failed to reject sender ID.");
      })
      .finally(() => {
        setRejectingSenderId(false);
        setSenderId(null);
      });
  };

  useEffect(() => {
    setIsFetching(true);
    api.admin
      .getSingleUser(id)
      .then((res) =>
        setUserDetails({
          campaigns: res.data.data.campaigns,
          voiceReports: res.data.data.voice_sms,
          senderIDs: res.data.data.sender_ids,
          ownNumbers: res.data.data.own_numbers,
          virtualNumbers: res.data.data.virtual_numbers,
          transactions: res.data.data.transactions,
          name: res.data.data.name,
          email: res.data.data.email,
          espee_balance: res.data.data.espee_balance,
          created_at: res.data.data.created_at,
          last_login: res.data.data.last_login,
          status: res.data.data.status,
          photo: res.data.data.photo
        })
      )
      .catch(console.error)
      .finally(() => setIsFetching(false));
  }, [id]);

  const getDataForActiveTab = () => {
    switch (activeTab) {
      case 0:
        return userDetails.campaigns;
      case 1:
        return userDetails.voiceReports;
      case 2:
        return userDetails.transactions;
      case 3:
        return userDetails.senderIDs;
      case 4:
        return userDetails.ownNumbers;
      case 5:
        return userDetails?.virtualNumbers || [];
      default:
        return [];
    }
  };

  // Get the data for the active tab
  const activeData = getDataForActiveTab();

  // Calculate the total number of pages for the active tab
  const pageCount = Math.ceil(activeData?.length / itemsPerPage);

  // Get the paginated data for the active tab
  const paginatedData = (activeData || []).slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Handle tab changes
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setCurrentPage(0);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = paginatedData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItems = (e) => {
    setItemsPerPage(e.target.value);
  };

  const handleSpeak = (msg) => {
    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(msg);
      speechSynthesis.speak(utterance);
    } else {
      alert("Sorry, your browser does not support text to speech!");
    }
  };

  const handleActiveness = () => {
    if (userDetails.status === "active") {
      api.admin
        .deactivateUser(id)
        .then((res) => {
          toast.success(res.data.message);
        })
        .catch((error) => {
          toast.error("Failed to deactivate user.");
        });
    } else {
      api.admin
        .activateUser(id)
        .then((res) => {
          toast.success(res.data.message);
        })
        .catch((error) => {
          toast.error("Failed to activate user.");
        });
    }
  };


  const deleteThisUser = () => {
    api.admin.deleteUser(id).then(res => navigate('/dashboard/admin/manage-users')).catch(console.error)
  }

  return (
    <div className="min-h-screen p-4 bg-gray-100 w-full">
      {/* First Section: Profile and Tabs */}
      <div className="p-6 shadow-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Profile Section */}
          <div className="flex flex-col items-center md:items-start">
            {isFetching ? (
              <Skeleton
                variant="circular"
                width={128}
                height={128}
                className="mb-4"
              />
            ) : (
              <img
                src={userDetails.photo || "https://via.placeholder.com/150"}
                alt="User Profile"
                className="rounded-full w-32 h-32 mb-4"
              />
            )}

            {isFetching ? (
              <Skeleton variant="text" width={150} height={30} />
            ) : (
              <h2 className="text-xl font-semibold text-center md:text-left">
                {userDetails?.name}
              </h2>
            )}
            {isFetching ? (
              <Skeleton variant="text" width={200} height={20} />
            ) : (
              <p className="text-gray-600 text-center md:text-left">
                {userDetails?.email}
              </p>
            )}
            {isFetching ? (
              <Skeleton variant="text" width={100} height={20} />
            ) : (
              <p className="text-gray-600 text-center md:text-left">
                {userDetails?.espee_balance?.toFixed(2)} ESPEES
              </p>
            )}
          </div>
          {/* Tabs Section */}
          <div className="flex flex-col">
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Messages" />
              <Tab label="Voice SMS" />
              <Tab label="Transactions" />
              <Tab label="Sender ID" />
              <Tab label="Virtual Number" />
              <Tab label="Own Number" />
            </Tabs>
          </div>
        </div>

        {/* Message User Buttons */}
        <div className="my-4 flex flex-col sm:flex-row sm:gap-4 gap-2 w-full">
          <Button
            variant="contained"
            color="primary"
            className="w-full sm:w-auto"
            onClick={() => setIsSendingMessage(true)}
          >
            Message User
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#8a1515" }}
            className="w-full sm:w-auto"
            onClick={handleActiveness}
          >
            {userDetails.status === "active" ? "Disable" : "Activate"}
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: "red" }}
            className="w-full sm:w-auto"
            onClick={deleteThisUser}
          >
            Delete User
          </Button>
        </div>
      </div>


      {/* Second Section: Tab Content */}
      <div className="mt-[40px] ">
        {activeTab === 0 && (
          <div className="w-full overflow-x-auto">
            <div className="my-4">
              <Typography variant="h6">Campaign History</Typography>
            </div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>S/N</TableCell>
                    {!isMobile && <TableCell>No Of Failed Contacts</TableCell>}
                    <TableCell>No Of Sent Contacts</TableCell>
                    <TableCell>Status</TableCell>
                    {!isMobile && (
                      <>
                        <TableCell>Created At</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody className='w-[50%] overflow-x-auto'>
                  {isFetching ? (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    currentItems.map((campaign, idx) => (
                      <TableRow key={campaign.id}>
                        <TableCell className="text-wrap w-[20px]">
                          {idx + 1}
                        </TableCell>
                        {!isMobile && <TableCell className="text-wrap w-[10px]">
                          {campaign.no_of_failed_contacts || 0}
                        </TableCell>}
                        <TableCell className="text-wrap w-[10px]">
                          {campaign.no_of_sent_contacts}
                        </TableCell>
                        <TableCell className="text-wrap w-[10px]">
                          {campaign.status}
                        </TableCell>
                        {!isMobile && (
                          <>
                            <TableCell className="text-wrap w-[90px]">
                              {new Date(campaign.created_at).toLocaleString()}
                            </TableCell>
                          </>
                        )}
                        <TableCell className="text-wrap w-[10px]">
                          <Button onClick={() => handleOpenModal(campaign)}>
                            View Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        {activeTab === 1 && (
          <section className="w-full overflow-auto">
            <div className="mb-5">
              <h1 className="text-2xl font-bold ">Voice Reports</h1>
            </div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {!isMobile && <TableCell>S/N</TableCell>}
                    {!isMobile && <TableCell>No Of Sent Contacts</TableCell>}
                    {!isMobile && <TableCell>Status</TableCell>}
                    <TableCell>ESPEES Deducted</TableCell>
                    {!isMobile && <TableCell>Refunded Amount</TableCell>}
                    <TableCell>Audio File</TableCell>
                    {!isMobile && (
                      <>
                        <TableCell>Created At</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody className="w-full overflow-x-auto">
                  {isFetching ? (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    currentItems.map((report, idx) => (
                      <TableRow key={report.id}>
                        {!isMobile && <TableCell sx={{ width: "10px" }}>{idx + 1}</TableCell>}
                        {!isMobile && <TableCell className="text-wrap w-[10px]">
                          {report.no_of_sent_contacts}
                        </TableCell>}
                        {!isMobile && <TableCell sx={{ width: "10px" }}>
                          {report.status}
                        </TableCell>}
                        <TableCell>{report.espees_deducted}</TableCell>
                        {!isMobile && <TableCell>{report.refunded_amount || 0.0}</TableCell>}
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            href={report.audioFileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              report.audioFileUrl === null &&
                              handleSpeak(report.msg)
                            }
                          >
                            Listen
                          </Button>
                        </TableCell>
                        {!isMobile && (
                          <TableCell>
                            {new Date(report.created_at).toLocaleString()}
                          </TableCell>
                        )}
                        <TableCell className="text-wrap w-[10px]">
                          <Button onClick={() => handleOpenModal(report)}>
                            View Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </section>
        )}
        {activeTab === 2 && (
          <div className="w-full overflow-x-auto">
            <Typography variant="h6" gutterBottom>
              Assign failed ESPEES transaction
            </Typography>
            {isFetching ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "50vh",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ margin: "20px 0" }}>
                <div className="mb-6">
                  <Typography variant="h4" sx={{ fontSize: "15px" }}>
                    Input ESPEES Amount and Payment reference
                  </Typography>
                  <div className="flex flex-col lg:flex-row gap-3 w-full mt-4">

                    <TextField
                      label="ESPEES Amount"
                      variant="outlined"
                      name="number"
                      value={espee.number}
                      onChange={handleEspeeChange}
                      required
                      sx={{ marginBottom: 2 }}
                    />
                    <TextField
                      label="Payment Reference"
                      variant="outlined"
                      name="payment_ref"
                      value={espee.payment_ref}
                      onChange={handleEspeeChange}
                      required
                      sx={{ marginBottom: 2 }}
                    />
                  </div>
                  <Button variant="contained" color="primary" onClick={Assign}>
                    {assigning ? (
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    ) : (
                      "Approve"
                    )}
                  </Button>
                </div>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {!isMobile && <TableCell>S/N</TableCell>}
                        <TableCell>Payment Reference</TableCell>
                        {!isMobile && <TableCell>ESPEE</TableCell>}
                        {!isMobile && <TableCell>Status</TableCell>}
                        {!isMobile && <TableCell>Transaction Date</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody className="overflow-x-auto">
                      {currentItems.map((transaction, idx) => (
                        <TableRow key={transaction.id}>
                          {!isMobile && <TableCell>{idx + 1}</TableCell>}
                          <TableCell ><p className={`text-wrap truncate ${isMobile && 'w-[180px]'}`}>{transaction.payment_ref}</p></TableCell>
                          {!isMobile &&
                            (<>
                              <TableCell>{transaction.price}</TableCell>
                              <TableCell>{transaction.status_details}</TableCell>
                            </>
                            )
                          }
                          {!isMobile && <TableCell>{transaction.transaction_date}</TableCell>}
                          <TableCell className="text-wrap w-[10px]">
                            <Button onClick={() => handleOpenModal(transaction)}>
                              View Details
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </div>
        )}
        {activeTab === 3 && (
          <div>
            <Typography variant="h5" gutterBottom>
              Approve Sender IDs
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>S/N</TableCell>
                    <TableCell>Sender ID</TableCell>
                    {!isMobile &&<TableCell>Status</TableCell>}
                    {!isMobile &&<TableCell>Created At</TableCell>}
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="w-full overflow-x-auto">
                  {currentItems.map((sender, idx) => (
                    <TableRow key={sender.id}>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>{sender.sender_id}</TableCell>
                      {!isMobile &&<TableCell>{sender.status}</TableCell>}
                      {!isMobile &&<TableCell>
                        {new Date(sender.created_at).toLocaleString()}
                      </TableCell>}
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={
                            senderId === sender.id ||
                            sender.status === "approved"
                          }
                          onClick={() => handleApprove(sender.id)}
                          sx={{ marginRight: 1 }}
                        >
                          {senderId === sender.id ? (
                            <CircularProgress size={24} />
                          ) : sender.status === "pending" ||
                            sender.status === "disapproved" ? (
                            "Approve"
                          ) : (
                            "Approved"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={
                            senderId === sender.id ||
                            sender.status === "disapproved"
                          }
                          onClick={() => handleReject(sender.id)}
                        >
                          {senderId === sender.id ? (
                            <CircularProgress size={24} />
                          ) : sender.status === "pending" ||
                            sender.status === "approved" ? (
                            "Reject"
                          ) : (
                            "Rejected"
                          )}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        {activeTab === 4 && (
          <Box sx={{ margin: "20px 0" }}>
            <div className="mb-6">
              <Typography variant="p" sx={{ fontSize: "20px" }} gutterBottom>
                Input Virtual Number
              </Typography>
              <div className="flex flex-col lg:flex-row gap-3 w-full mt-4">
                <TextField
                  label="Virtual Number"
                  variant="outlined"
                  name="number"
                  value={virtual.number}
                  onChange={handleVirtual}
                  fullWidth
                  required
                  sx={{ marginBottom: 2 }}
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={ApproveVirtualNumber}
              >
                {approving ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Approve"
                )}
              </Button>
            </div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>S/N</TableCell>
                    <TableCell>Virtual Number</TableCell>
                    {!isMobile &&<TableCell>Status</TableCell>}
                    {!isMobile &&<TableCell>Created At</TableCell>}
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="w-full overflow-x-auto">
                  {currentItems.map((sender, idx) => (
                    <TableRow key={sender.id}>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>{sender.virtual_num}</TableCell>
                      {!isMobile &&<TableCell>{sender.status}</TableCell>}
                      {!isMobile &&<TableCell>
                        {new Date(sender.created_at).toLocaleString()}
                      </TableCell>}
                      <TableCell>
                        <Button
                          variant="contained"
                          color="secondary"
                        // onClick={() => handleDelete(sender.id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {activeTab === 5 && (
          <Box sx={{ padding: 2 }}>
            <Typography variant="h5" gutterBottom>
              All Own Numbers
            </Typography>
            {isFetching ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "50vh",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ margin: "20px 0" }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>S/N</TableCell>
                        <TableCell>Own Number</TableCell>
                        <TableCell>Status</TableCell>
                        {!isMobile &&<TableCell>Created At</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody className="w-full overflow-x-auto">
                      {currentItems.map((sender, idx) => (
                        <TableRow key={sender.id}>
                          <TableCell>{idx + 1}</TableCell>
                          <TableCell>{sender.phone_number}</TableCell>
                          <TableCell>{sender.status}</TableCell>
                          {!isMobile &&<TableCell>
                            {new Date(sender.created_at).toLocaleString()}
                          </TableCell>}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </Box>
        )}
      </div>
      <div>
        <select
          name="items"
          value={itemsPerPage}
          id="itemsperpage"
          onChange={handleItems}
          className="m-4 border border-[#1977CC]"
        >
          <option value={20}>20</option>
          <option value={40}>40</option>
          <option value={60}>60</option>
        </select>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"flex justify-center gap-2 my-12"}
          previousLinkClassName={
            "rounded-full border px-4 py-2 mr-2 bg-red-500 text-white"
          }
          nextLinkClassName={
            "rounded-full border px-4 py-2 ml-2 bg-asparagus-500 text-[#1977CC]"
          }
          breakClassName={"text-blue-500 cursor-pointer"}
          activeClassName={"bg-blue-500 text-white border rounded-full p-2"}
        />
      </div>
      {sendingMessage && (
        <MessageModal
          user={user}
          open={sendingMessage}
          onClose={() => setIsSendingMessage(false)}
        />
      )}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: "100%",
            maxWidth: "700px",
            maxHeight: "700px",
            overflow: "scroll",
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
            Status:
            <Typography variant="h6" sx={{ wordWrap: "break-word" }}>
              {selectedCampaign?.status || selectedCampaign?.status_details}
            </Typography>
          </Typography>
          <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
            Contacts:
            <Typography variant="h6" sx={{ wordWrap: "break-word" }}>
              {selectedCampaign?.contacts || 'N/A'}
            </Typography>
          </Typography>
          <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
            Failed Contacts:
            <Typography variant="h6" sx={{ wordWrap: "break-word" }}>
              {selectedCampaign?.failed_contacts || 0}
            </Typography>
          </Typography>
          <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
            Units:
            <Typography variant="h6" sx={{ wordWrap: "break-word" }}>
              {selectedCampaign?.units || 'N/A'}
            </Typography>
          </Typography>
          <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
            Broadcast ID:
            <Typography variant="h6" sx={{ wordWrap: "break-word" }}>
              {selectedCampaign?.broadcastId || 'N/A'}
            </Typography>
          </Typography>
          <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
            Payment Ref:
            <Typography variant="h6" sx={{ wordWrap: "break-word" }}>
              {selectedCampaign?.payment_ref || 'N/A'}
            </Typography>
          </Typography>
          <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
            ESPEE:
            <Typography variant="h6" sx={{ wordWrap: "break-word" }}>
              {selectedCampaign?.price || 'N/A'}
            </Typography>
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Message:
            <Typography variant="h6" sx={{ wordWrap: "break-word" }}>
              {selectedCampaign?.msg || 'N/A'}
            </Typography>
          </Typography>
          <Button onClick={handleCloseModal} sx={{ mt: 3 }}>
            Close
          </Button>
        </Box>
      </Modal>
      <RejectionModal
        open={rejecting}
        onClose={() => setIsRejecting(false)}
        onSubmit={handleSubmitRejection}
        loading={rejectingSenderId}
      />
    </div>
  );
};

export default SingleUser;
